// eslint-disable-next-line import/prefer-default-export,no-shadow
export const LoadingState = {
  Initial: "initial",
  Loading: "loading",
  Error: "error",
};
export type LoadingState = typeof LoadingState[keyof typeof LoadingState];

export type LoadableValue<T> = {
  loadingState: LoadingState;
  value?: T;
};
