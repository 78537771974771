import * as React from "react";
import { connect } from "react-redux";
import InvitationErrorStateView from "../../components/invitations/InvitationErrorStateView";
import { InvitationErrorReason } from "../../modules/invitations";

interface ExportProps {
  reason: InvitationErrorReason;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class InvitationErrorStateViewContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { reason } = this.props;
    return <InvitationErrorStateView reason={reason} />;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationErrorStateViewContainer);
