import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import styles from "./PageSectionTitleStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const PageSectionTitle: React.FC<Props> = ({ classes, children }) => (
  <Typography className={classes.root} component="h2" variant="h6">
    {children}
  </Typography>
);

export default withTranslation()(withStyles(styles)(PageSectionTitle));
