import { Button } from "@mui/material";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./IndexPageStyles";
import PageContainer from "../parts/PageContainer";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  onRequestedUserMenu: () => void;
}

const IndexPage: React.FC<Props> = ({ onRequestedUserMenu, classes }) => (
  <PageContainer>
    <div className={classes.contentContainer}>
      <p>PRODUCTION C-time!のサービス共通で使うID管理システムです</p>
      <div>
        <Button fullWidth variant="contained" onClick={onRequestedUserMenu}>
          利用サービスの確認・プロフィールの編集
        </Button>
      </div>
    </div>
  </PageContainer>
);

export default withTranslation()(withStyles(styles)(IndexPage));
