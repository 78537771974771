import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    list: {
      width: "100%",
    },
    listItemButton: {
      overflow: "hidden",
    },
    menuButton: {
      marginTop: theme.spacing(1),
    },
    backButton: {
      marginTop: theme.spacing(2),
    },
  });

export default styles;
