import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";

// models
// ----------------------------------------

// State
// ----------------------------------------

export interface WindowsState {
  drawerPinned: boolean;
  storedBackPath?: string | null;
}

export const EmptyWindowsState: WindowsState = {
  drawerPinned: false,
};

const initialState: WindowsState = EmptyWindowsState;

// Parameters
// ----------------------------------------

export interface UpdateDrawerPinnedStateParameter {
  drawerPinned: boolean;
}

export interface NavigateLocationParameters {
  url: string;
}

export interface StoreBackPathParameters {
  path: string;
}

export interface PoppedBackPathParameters {
  path: string | null;
}

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeIDp/Windows");

export const navigateLocationAction =
  actionCreator<NavigateLocationParameters>("NavigateLocation");

export const storeBackPathAction =
  actionCreator<StoreBackPathParameters>("storeBackPath");

export const popBackPathAction = actionCreator<void>("popBackPath");

export const poppedBackPathAction =
  actionCreator<PoppedBackPathParameters>("poppedBackPath");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState).case(
  poppedBackPathAction,
  (state, { path }) => ({ ...state, storedBackPath: path }),
);

export default reducer;
