import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { replace } from "connected-react-router";
import UserLoadingStateViewContainer from "../user/UserLoadingStateViewContainer";
import { RootState } from "../../modules";
import { popBackPathAction } from "../../modules/windows";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  backPath: string | null | undefined;
}

interface DispatchProps {
  popBackPath: () => void;
  showPath: (path: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const isAllowedPath = (path: string): boolean => {
  if (path.startsWith("/user/")) {
    return true;
  }
  // noinspection RedundantIfStatementJS
  if (path.startsWith("/invitations/")) {
    return true;
  }
  return false;
};

class AuthorizedPageContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    const { popBackPath } = this.props;
    popBackPath();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { backPath, showPath, popBackPath } = this.props;
    if (backPath !== prevProps.backPath) {
      if (backPath && isAllowedPath(backPath)) {
        showPath(backPath);
      } else if (backPath !== undefined) {
        showPath("/user/");
      }
      popBackPath();
    }
  }

  public render(): JSX.Element {
    return <UserLoadingStateViewContainer />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  backPath: state.windows.storedBackPath,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  popBackPath: () => dispatch(popBackPathAction()),
  showPath: (path) => dispatch(replace(path)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorizedPageContainer);
