import { Typography } from "@mui/material";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import styles from "./LoginEmailSentViewStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const LoginEmailSentView: React.FC<Props> = ({ classes, t }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <DoneIcon />
      <Typography className={classes.message}>
        {t("Sign In Email Link Sent")}
      </Typography>
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(LoginEmailSentView));
