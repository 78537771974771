import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "@mui/material";
import classNames from "classnames";
import styles from "./InvitationGroupViewStyles";
import PageTitle from "../../parts/PageTitle";
import PageSectionTitle from "../../parts/PageSectionTitle";
import MenuBox from "../../parts/MenuBox";
import UserAvatarView from "../../parts/UserAvatarView";
import { UserProfile } from "../../../modules/userProfile";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  userProfile: UserProfile | undefined;
  groupName: string;
  onAccept: () => void;
  onSwitchAccount: () => void;
  onDecline: () => void;
}

const InvitationGroupView: React.FC<Props> = ({
  userProfile,
  groupName,
  onAccept,
  onSwitchAccount,
  onDecline,
  classes,
  t,
}) => (
  <MenuBox>
    <UserAvatarView userProfile={userProfile} />
    <PageTitle>{t("Confirmation Group Invitation Title")}</PageTitle>
    <PageSectionTitle>{groupName}</PageSectionTitle>
    <div>{t("Confirmation Group Invitation Description")}</div>
    <div className={classes.menuItems}>
      <Button
        className={classes.menuButton}
        fullWidth
        color="primary"
        variant="contained"
        onClick={onAccept}
      >
        {t("Accept Invitation")}
      </Button>
      <Button
        className={classNames(classes.menuButton)}
        fullWidth
        onClick={onSwitchAccount}
      >
        {t("Use other account")}
      </Button>
      <Button
        className={classNames(
          classes.menuButton,
          classes.backButton,
          classes.declineButton,
        )}
        fullWidth
        onClick={onDecline}
      >
        {t("Decline Invitation")}
      </Button>
    </div>
  </MenuBox>
);

export default withTranslation()(withStyles(styles)(InvitationGroupView));
