import {
  doc,
  getFirestore,
  onSnapshot,
  collection,
  query,
  where,
  documentId,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { eventChannel, SagaIterator } from "redux-saga";
import {
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "typed-redux-saga";
import { Action } from "typescript-fsa";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import { v4 as uuid } from "uuid";
import { getApiFunctionsInstanceAsync } from "../firebase";
import {
  changeUserProfileAction,
  NewAvatarData,
  requestUserMetaDataAction,
  RequestUserMetaDataParameter,
  updateUserMetaDataAction,
  updateUserProfileAction,
  UpdateUserProfileParameter,
  updateUserProfileProgressAction,
  uploadNewAvatarAction,
  UploadNewAvatarParameter,
  uploadNewAvatarProgressAction,
  UserProfile,
  watchUserProfileAction,
  WatchUserProfileParameter,
} from "./index";
import { MetaUser } from "../../../functions/src/models";
import { chunk, diff, uniq } from "../../lib/arrayOperations";
import { RootState } from "../index";

function* watchUserProfileSaga(action: Action<WatchUserProfileParameter>) {
  if (!action.payload.userId) {
    return;
  }
  const { userId } = action.payload;
  const subscribe = () =>
    eventChannel<UserProfile>((emitter) => {
      const db = getFirestore();
      const userProfileQuery = onSnapshot(
        doc(db, `users/${userId}`),
        (snapshot) => {
          const data = snapshot.data() as UserProfile;
          if (!data) {
            return;
          }
          data.id = snapshot.id;
          emitter(data);
        },
      );
      return () => userProfileQuery;
    });

  const channel = yield* call(subscribe);
  yield* fork(function* stopWatcherSaga() {
    yield* take(watchUserProfileAction);
    channel.close();
  });
  try {
    while (true) {
      const userProfile = yield* take(channel);
      yield put(changeUserProfileAction({ userProfile }));
    }
  } finally {
    if (yield* cancelled()) {
      channel.close();
    }
  }
}

const uploadNewAvatarProgressSaga = bindAsyncAction(
  uploadNewAvatarProgressAction,
)(function* uploadNewAvatarProgressSagaAction(payload) {
  const { data, userId } = payload;
  const storage = getStorage();

  const id = uuid();
  if (!userId) {
    throw new Error("Unauthenticated");
  }

  const storageRef = ref(storage, `/tmp/${userId}/uploads/${id}`);
  const result = yield* call(uploadBytes, storageRef, data);
  const url = yield* call(getDownloadURL, result.ref);
  return {
    fileId: id,
    avatarUrl: url,
  } as NewAvatarData;
});

function* uploadNewAvatarSaga(action: Action<UploadNewAvatarParameter>) {
  yield* call(uploadNewAvatarProgressSaga, action.payload);
}

const updateUserProfileProgressSaga = bindAsyncAction(
  updateUserProfileProgressAction,
)(function* updateUserProfileProgressSagaAction(payload) {
  const functions = yield* call(getApiFunctionsInstanceAsync);
  return yield* call(
    functions.updateUserProfileAsync,
    payload.form.name,
    payload.newAvatar,
  );
});

function* updateUserProfileSaga(action: Action<UpdateUserProfileParameter>) {
  yield* call(updateUserProfileProgressSaga, action.payload);
}

function* requestUserMetaDataSaga(
  action: Action<RequestUserMetaDataParameter>,
) {
  const { userIds } = action.payload;
  const state = (yield* select()) as RootState;
  const existKeys = Object.keys(state.userProfile.metaUsers);
  const targetIds = diff(uniq(userIds), existKeys);
  if (targetIds.length === 0) {
    return;
  }

  const chunks = chunk(targetIds, 10);

  const db = getFirestore();
  const metaUserCollection = collection(db, "metaUsers");
  const metaUsers = yield* call(async () => {
    const resultChunks = await Promise.all(
      chunks.map(async (ids) => {
        const snapshot = await getDocs(
          query(metaUserCollection, where(documentId(), "in", ids)),
        );
        const result: MetaUser[] = [];
        snapshot.forEach((metaUserDoc) =>
          result.push({
            ...(metaUserDoc.data() as MetaUser),
            id: metaUserDoc.id,
          }),
        );
        return result;
      }),
    );
    return Array.prototype.concat.apply([], resultChunks) as MetaUser[];
  });
  yield* put(updateUserMetaDataAction({ metaUsers }));
}

export default function* watchUserProfile(): SagaIterator {
  yield* takeLatest(watchUserProfileAction.type, watchUserProfileSaga);
  yield* takeEvery(uploadNewAvatarAction.type, uploadNewAvatarSaga);
  yield* takeEvery(updateUserProfileAction.type, updateUserProfileSaga);
  yield* takeEvery(requestUserMetaDataAction.type, requestUserMetaDataSaga);
}
