export const chunk = <T>(arr: T[], size: number): T[][] =>
  arr.reduce(
    (newArray, _, i) =>
      i % size ? newArray : [...newArray, arr.slice(i, i + size)],
    [] as T[][],
  );

export const diff = <T>(baseArr: T[], distArr: T[]): T[] =>
  baseArr.filter((v) => !distArr.find((d) => d === v));

export const uniq = <T>(array: T[]): T[] => [...new Set(array)];
