import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      textAlign: "center",
    },
    iconContainer: {
      marginTop: theme.spacing(3),
    },
    icon: {
      fontSize: "128px",
    },
    resultText: {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.h4.fontWeight,
    },
    captionText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.body1.fontWeight,
    },
  });

export default styles;
