import { replace } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import IndexPage from "../../components/pages/IndexPage";
import { RootState } from "../../modules";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

interface DispatchProps {
  navigateToMenu: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class IndexPageContainer extends React.PureComponent<Props> {
  private handleRequestedUserMenu = () => {
    const { navigateToMenu } = this.props;
    navigateToMenu();
  };

  public render(): JSX.Element {
    return <IndexPage onRequestedUserMenu={this.handleRequestedUserMenu} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  firebaseAuthenticationStatus: state.firebaseAuthentication.status,
  idpMenuLoginRequest: state.loginRequests.idpMenuLoginRequest,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigateToMenu: () => dispatch(replace("/user")),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexPageContainer);
