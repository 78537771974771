import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "@mui/material";
import styles from "./InvitationLoginRequestViewStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  onLogin: () => void;
}

const InvitationLoginRequestView: React.FC<Props> = ({
  onLogin,
  classes,
  t,
}) => (
  <div className={classes.root}>
    <p>{t("Invitation Login Request Description")}</p>
    <div>
      <Button fullWidth variant="contained" onClick={onLogin}>
        {t("Login or Account Creation")}
      </Button>
    </div>
  </div>
);

export default withTranslation()(
  withStyles(styles)(InvitationLoginRequestView),
);
