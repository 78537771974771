import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoginRequestPage from "../../components/login/LoginRequestPage";
import { RootState } from "../../modules";
import {
  getLoginRequestAction,
  LoginRequest,
} from "../../modules/loginRequests";
import { LoadingState } from "../../modules/models";
import LoginErrorStateViewContainer from "./LoginErrorStateViewContainer";
import LoginLoadingStateViewContainer from "./LoginLoadingStateViewContainer";
import LoginRouterContainer from "./LoginRouterContainer";

interface ExportProps {
  match: { params: { id: string } };
}

interface StateProps {
  loadingState: LoadingState;
  currentRequest?: LoginRequest;
}

interface DispatchProps {
  getLoginRequest: (id: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class LoginRequestPageContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    const { match, getLoginRequest } = this.props;
    const { id } = match.params;
    if (!id) {
      return;
    }
    getLoginRequest(id);
  }

  private view(): JSX.Element {
    const { currentRequest, loadingState } = this.props;
    if (loadingState === LoadingState.Loading) {
      return <LoginLoadingStateViewContainer />;
    }
    if (loadingState === LoadingState.Error) {
      return <LoginErrorStateViewContainer />;
    }
    if (
      currentRequest?.status === "error" ||
      currentRequest?.status === "verified"
    ) {
      return <LoginErrorStateViewContainer />;
    }
    return <LoginRouterContainer />;
  }

  public render(): JSX.Element {
    return <LoginRequestPage>{this.view()}</LoginRequestPage>;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  loadingState: state.loginRequests.requestLoadingState,
  currentRequest: state.loginRequests.currentRequest,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getLoginRequest: (id) => dispatch(getLoginRequestAction({ id })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginRequestPageContainer);
