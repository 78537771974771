import { Functions, httpsCallable } from "firebase/functions";
import {
  IdpMenuLoginResult,
  LoginCompletionResult,
  LoginRequest,
} from "../loginRequests";
import { UserProfile } from "../userProfile";
import { Group, GroupEditParameter, GroupInvitation } from "../groups";
import { GetInvitationResult } from "../../../functions/src/models";

interface CallableFunctionsRequest<T> {
  function: string;
  data: T;
}

export default class ApiFunctions {
  private readonly functions: Functions;

  public constructor(functions: Functions) {
    this.functions = functions;
  }

  public getLoginRequestAsync = (id: string): Promise<LoginRequest> =>
    this.callFunctionAsync("getLoginRequest", { id });

  public completeLoginRequestAsync = (
    id: string,
  ): Promise<LoginCompletionResult> =>
    this.callFunctionAsync("completeLoginRequest", { id });

  public createIdpMenuLoginAsync = (): Promise<IdpMenuLoginResult> =>
    this.callFunctionAsync("createIdpMenuLogin", {});

  public updateUserProfileAsync = (
    name: string,
    newAvatar: string,
  ): Promise<UserProfile> =>
    this.callFunctionAsync("updateUserProfile", { name, newAvatar });

  public createGroupAsync = (parameter: GroupEditParameter): Promise<Group> =>
    this.callFunctionAsync("createGroup", parameter);

  public editGroupAsync = (
    id: string,
    parameter: GroupEditParameter,
  ): Promise<Group> =>
    this.callFunctionAsync("editGroup", { ...parameter, id });

  public deleteGroupAsync = (id: string): Promise<void> =>
    this.callFunctionAsync("deleteGroup", { id });

  public inviteGroupAsync = (id: string, email: string): Promise<void> =>
    this.callFunctionAsync("inviteToGroup", { id, email });

  public cancelInvitationToGroupAsync = (
    groupId: string,
    invitationId: string,
  ): Promise<GroupInvitation> =>
    this.callFunctionAsync("cancelInvitationToGroup", {
      groupId,
      invitationId,
    });

  public changeMemberRoleInGroupAsync = (
    groupId: string,
    userId: string,
    role: string,
  ): Promise<void> =>
    this.callFunctionAsync("changeMemberRoleInGroup", {
      groupId,
      userId,
      role,
    });

  public removeMemberFromGroupAsync = (
    groupId: string,
    userId: string,
  ): Promise<void> =>
    this.callFunctionAsync("removeMemberFromGroup", {
      groupId,
      userId,
    });

  public getInvitationAsync = (
    verificationId: string,
  ): Promise<GetInvitationResult> =>
    this.callFunctionAsync("getInvitation", {
      verificationId,
    });

  public acceptGroupInvitationAsync = (
    groupId: string,
    invitationId: string,
    verificationId: string,
  ): Promise<GetInvitationResult> =>
    this.callFunctionAsync("acceptGroupInvitation", {
      groupId,
      invitationId,
      verificationId,
    });

  public declineGroupInvitationAsync = (
    groupId: string,
    invitationId: string,
    verificationId: string,
  ): Promise<GetInvitationResult> =>
    this.callFunctionAsync("declineGroupInvitation", {
      groupId,
      invitationId,
      verificationId,
    });

  private async callFunctionAsync<
    RequestData = unknown,
    ResponseData = unknown,
  >(functionName: string, data: RequestData): Promise<ResponseData> {
    const callable = httpsCallable<
      CallableFunctionsRequest<RequestData>,
      ResponseData
    >(this.functions, "callableFunctions");
    const result = await callable({ function: functionName, data });
    return result.data;
  }
}
