import { CssBaseline } from "@mui/material";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./RootStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const Root: React.FC<Props> = ({ children, classes }) => (
  <>
    <CssBaseline />
    <div className={classes.root}>{children}</div>
  </>
);

export default withTranslation()(withStyles(styles)(Root));
