import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import styles from "./InvitationReplyResultViewStyles";
import { InvitationReplyType } from "../../modules/invitations";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  reply: InvitationReplyType;
}

const InvitationReplyResultView: React.FC<Props> = ({ reply, classes, t }) => (
  <div className={classes.root}>
    <div className={classes.iconContainer}>
      <CheckIcon fontSize="large" className={classes.icon} />
    </div>
    <div className={classes.resultText}>
      {reply === InvitationReplyType.Accept &&
        t("Invitation Reply Result Accept")}
      {reply === InvitationReplyType.Decline &&
        t("Invitation Reply Result Decline")}
    </div>
    <div className={classes.captionText}>
      {t("Invitation Reply Close Window")}
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(InvitationReplyResultView));
