import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./NotFoundPageStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const NotFoundPage: React.FC<Props> = ({ classes }) => (
  <div className={classes.root}>NotFoundPage</div>
);

export default withTranslation()(withStyles(styles)(NotFoundPage));
