import { Theme } from "@mui/material";
import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    menuButton: {
      marginTop: theme.spacing(1),
    },
    backButton: {
      marginTop: theme.spacing(2),
    },
  });

export default styles;
