import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import styles from "./PageTitleStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const PageTitle: React.FC<Props> = ({ classes, children }) => (
  <Typography component="h1" variant="h5" className={classes.root}>
    {children}
  </Typography>
);

export default withTranslation()(withStyles(styles)(PageTitle));
