import { initializeApp, FirebaseApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, Firestore } from "firebase/firestore";
import {
  GoogleAuthProvider,
  UserCredential,
  getAuth,
  onAuthStateChanged,
  signInWithRedirect,
  signOut,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import { Store } from "redux";
import { changeFirebaseUserAction } from "../firebaseAuthentication";
import ApiFunctions from "./functions";
import sleep from "../../lib/sleep";

let firebaseApp: FirebaseApp;
let apiFunctionsInstance: ApiFunctions;
let firestoreInstance: Firestore;
const provider = new GoogleAuthProvider();

export const getFirestoreInstanceAsync = async (): Promise<Firestore> => {
  while (!firestoreInstance) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000);
  }
  return firestoreInstance;
};

export const getApiFunctionsInstanceAsync = async (): Promise<ApiFunctions> => {
  while (!apiFunctionsInstance) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000);
  }
  return apiFunctionsInstance;
};

export const setupFirebase = (config: Record<string, string>): void => {
  if (firebaseApp) {
    return;
  }
  firebaseApp = initializeApp(config);
  /*
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(recaptchaKey),
    isTokenAutoRefreshEnabled: true,
  });
   */
  const functions = getFunctions(firebaseApp, "asia-northeast1");
  if (process.env.REACT_APP_FUNCTIONS_EMULATOR) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }
  apiFunctionsInstance = new ApiFunctions(functions);
  firestoreInstance = getFirestore(firebaseApp);
};

export const setupAuthentication = (store: Store): void => {
  onAuthStateChanged(getAuth(firebaseApp), (user) => {
    void (async () => {
      let userDataId: string | undefined;
      if (user) {
        let currentUser = await user.getIdTokenResult();
        if (!currentUser.claims.userDataId) {
          currentUser = await user.getIdTokenResult(true);
        }
        userDataId = currentUser.claims.userDataId as string;
      }
      store.dispatch(
        changeFirebaseUserAction({ user: user || undefined, userDataId }),
      );
    })();
  });
};
export const startGoogleSignInAsync = (): Promise<void> =>
  signInWithRedirect(getAuth(firebaseApp), provider);

export const startSignOutAsync = (): Promise<void> =>
  signOut(getAuth(firebaseApp));

export const startEmailSignIn = (url: string, email: string): Promise<void> =>
  sendSignInLinkToEmail(getAuth(firebaseApp), email, {
    url,
    handleCodeInApp: true,
  });

export const finishEmailSignIn = (
  email: string,
  href: string,
): Promise<UserCredential> =>
  signInWithEmailLink(getAuth(firebaseApp), email, href);
