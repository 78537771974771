import * as React from "react";
import { connect } from "react-redux";
import LoginErrorStateView from "../../components/login/LoginErrorStateView";
import { RootState } from "../../modules";
import { LoginRequest } from "../../modules/loginRequests";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  currentRequest?: LoginRequest;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class LoginErrorStateViewContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { currentRequest } = this.props;
    const reason = (() => {
      if (currentRequest?.status === "error") {
        return currentRequest.errorReason || "unknown";
      }
      if (currentRequest?.status === "verified") {
        return "verified";
      }
      return "general";
    })();

    return <LoginErrorStateView reason={reason} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentRequest: state.loginRequests.currentRequest,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginErrorStateViewContainer);
