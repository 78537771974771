import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../modules";
import { User } from "../../modules/firebaseAuthentication";
import LoginConfirmViewContainer from "./LoginConfirmViewContainer";
import LoginMenuViewContainer from "./LoginMenuViewContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  firebaseUser?: User;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class LoginRequestStatesContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { firebaseUser } = this.props;
    if (firebaseUser) {
      return <LoginConfirmViewContainer />;
    }
    return <LoginMenuViewContainer />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  firebaseUser: state.firebaseAuthentication.user,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginRequestStatesContainer);
