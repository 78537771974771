import { User as FirebaseUser } from "firebase/auth";
import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { LoadingState } from "../models";
import { createAsyncActionCreator } from "../actionCreatorHelpers";

// models
// ----------------------------------------

export type User = FirebaseUser;

export const FirebaseAuthenticationStatus = {
  Initial: "initial",
  NotAuthenticated: "notAuthenticated",
  Authenticated: "authenticated",
};
export type FirebaseAuthenticationStatus =
  typeof FirebaseAuthenticationStatus[keyof typeof FirebaseAuthenticationStatus];

export interface FirebaseAuthenticationEmailLoginState {
  id: string;
  email?: string;
}

// State
// ----------------------------------------

export interface FirebaseAuthenticationState {
  status: FirebaseAuthenticationStatus;
  user?: User;
  emailLoginLoadingState: LoadingState;
  emailLoginSent: boolean;
  emailLoginState?: FirebaseAuthenticationEmailLoginState;
  emailLoginCompleted: boolean;
}

const initialState: FirebaseAuthenticationState = {
  status: FirebaseAuthenticationStatus.Initial,
  emailLoginSent: false,
  emailLoginLoadingState: LoadingState.Initial,
  emailLoginCompleted: false,
};

// Action Parameters
// ----------------------------------------

export interface ChangeFirebaseUserActionParameters {
  user?: FirebaseUser;
  userDataId?: string;
}

export interface FirebaseEmailLoginRequestParameter {
  id: string;
  email: string;
}

export interface FirebaseEmailLoginCheckStateParameter {
  id: string;
}

export interface FirebaseEmailLoginCheckStateCompletionParameter {
  id: string;
  email?: string;
}

export interface FirebaseEmailLoginCompletionParameter {
  email: string;
}

// Action Creators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeIDp/FirebaseAuthentication");

export const changeFirebaseUserAction =
  actionCreator<ChangeFirebaseUserActionParameters>("ChangeUser");
// export const setUserInformation =
//   actionCreator<SetUserInformationActionParameters>("SetUserInformation");
export const requestFirebaseGoogleSignInAction = actionCreator<void>(
  "RequestGoogleSignIn",
);
export const requestFirebaseSignOutAction =
  actionCreator<void>("RequestSignOut");

export const [
  firebaseEmailLoginRequestAction,
  firebaseEmailLoginRequestProgressAction,
] = createAsyncActionCreator<FirebaseEmailLoginRequestParameter, void, Error>(
  actionCreator,
  "EmailLoginRequest",
);

export const firebaseEmailLoginCheckStateAction =
  actionCreator<FirebaseEmailLoginCheckStateParameter>("EmailLoginCheckState");
export const firebaseEmailLoginCheckStateCompletionAction =
  actionCreator<FirebaseEmailLoginCheckStateCompletionParameter>(
    "EmailLoginCheckStateCompletion",
  );

export const [
  firebaseEmailLoginCompletionAction,
  firebaseEmailLoginCompletionProgressAction,
] = createAsyncActionCreator<
  FirebaseEmailLoginCompletionParameter,
  void,
  Error
>(actionCreator, "EmailLoginCompletion");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(changeFirebaseUserAction, (state, { user }) => ({
    ...state,
    user,
    status:
      user === undefined
        ? FirebaseAuthenticationStatus.NotAuthenticated
        : FirebaseAuthenticationStatus.Authenticated /* state.status */,
    emailLoginSent: false,
    emailLoginCompleted: false,
  }))
  .case(firebaseEmailLoginRequestAction, (state) => ({
    ...state,
    emailLoginLoadingState: LoadingState.Loading,
    emailLoginSent: false,
    emailLoginCompleted: false,
  }))
  .case(firebaseEmailLoginRequestProgressAction.started, (state) => ({
    ...state,
    emailLoginLoadingState: LoadingState.Loading,
    emailLoginSent: false,
    emailLoginCompleted: false,
  }))
  .case(firebaseEmailLoginRequestProgressAction.done, (state) => ({
    ...state,
    emailLoginLoadingState: LoadingState.Initial,
    emailLoginSent: true,
  }))
  .case(firebaseEmailLoginRequestProgressAction.failed, (state) => ({
    ...state,
    emailLoginLoadingState: LoadingState.Error,
  }))
  .case(firebaseEmailLoginCheckStateCompletionAction, (state, payload) => ({
    ...state,
    emailLoginState: payload,
  }))
  .case(firebaseEmailLoginCompletionProgressAction.started, (state) => ({
    ...state,
    emailLoginLoadingState: LoadingState.Loading,
    emailLoginSent: false,
    emailLoginCompleted: false,
  }))
  .case(firebaseEmailLoginCompletionProgressAction.done, (state) => ({
    ...state,
    emailLoginLoadingState: LoadingState.Initial,
    emailLoginCompleted: true,
  }))
  .case(firebaseEmailLoginCompletionProgressAction.failed, (state) => ({
    ...state,
    emailLoginLoadingState: LoadingState.Error,
  }));

export default reducer;
