import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { RootState } from "../modules";
import LoginRequestPageContainer from "./login/LoginRequestPageContainer";
import IndexPageContainer from "./pages/IndexPageContainer";
import NotFoundPageContainer from "./pages/NotFoundPageContainer";
import UserPageContainer from "./user/UserPageContainer";
import AuthorizedPageContainer from "./pages/AuthorizedPageContainer";
import InvitationPageContainer from "./invitations/InvitationPageContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class Router extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <Switch>
        <Route path="/authorized" component={AuthorizedPageContainer} />
        <Route path="/login/:id" component={LoginRequestPageContainer} />
        <Route path="/invitations/:id" component={InvitationPageContainer} />
        <Route path="/user" component={UserPageContainer} />
        <Route exact path="/" component={IndexPageContainer} />
        <Route component={NotFoundPageContainer} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  location: state.router.location,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
