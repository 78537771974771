import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoginEmailCompletionView from "../../../components/login/email/LoginEmailCompletionView";
import emailValidation from "../../../lib/emailValidation";
import { RootState } from "../../../modules";
import {
  FirebaseAuthenticationEmailLoginState,
  firebaseEmailLoginCheckStateAction,
  firebaseEmailLoginCompletionAction,
} from "../../../modules/firebaseAuthentication";
import {
  completeLoginRequestAction,
  LoginRequest,
} from "../../../modules/loginRequests";
import { LoadingState } from "../../../modules/models";
import LoginErrorStateViewContainer from "../LoginErrorStateViewContainer";
import LoginLoadingStateViewContainer from "../LoginLoadingStateViewContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  currentRequest?: LoginRequest;
  emailLoginState?: FirebaseAuthenticationEmailLoginState;
  emailLoginLoadingState: LoadingState;
  emailLoginCompleted: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
  firebaseEmailLoginCheckState: (id: string) => void;
  firebaseEmailLoginCompletion: (email: string) => void;
  completeLoginRequest: (id: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  email: string;
}

class LoginEmailCompletionViewContainer extends React.PureComponent<
  Props,
  State
> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = { email: "" };
  }

  public componentDidMount() {
    const { currentRequest, firebaseEmailLoginCheckState } = this.props;
    const id = currentRequest?.id;
    if (id) {
      firebaseEmailLoginCheckState(id);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      currentRequest,
      emailLoginState,
      emailLoginCompleted,
      firebaseEmailLoginCompletion,
    } = this.props;
    const id = currentRequest?.id;
    const email = emailLoginState?.email;
    if (id && email && prevProps.emailLoginState === undefined) {
      firebaseEmailLoginCompletion(email);
    }
    if (!prevProps.emailLoginCompleted && emailLoginCompleted) {
      this.handleFinishLogin();
    }
  }

  private handleFinishLogin = () => {
    const { currentRequest, completeLoginRequest } = this.props;
    if (!currentRequest?.id) {
      return;
    }
    completeLoginRequest(currentRequest.id);
  };

  private handleEmailChange = (email: string) => this.setState({ email });

  private handleEnter = () => {
    const { currentRequest, firebaseEmailLoginCompletion } = this.props;
    const id = currentRequest?.id;
    const { email } = this.state;
    if (!id || !emailValidation(email)) {
      return;
    }
    firebaseEmailLoginCompletion(email);
  };

  public render(): JSX.Element {
    const {
      currentRequest,
      emailLoginState,
      emailLoginLoadingState,
      emailLoginCompleted,
    } = this.props;
    const { email } = this.state;
    switch (emailLoginLoadingState) {
      case LoadingState.Loading:
        return <LoginLoadingStateViewContainer />;
      case LoadingState.Error:
        return <LoginErrorStateViewContainer />;
      case LoadingState.Initial:
      default:
        if (
          emailLoginCompleted ||
          emailLoginState === undefined ||
          emailLoginState?.email !== undefined
        ) {
          return <LoginLoadingStateViewContainer />;
        }
        return (
          <LoginEmailCompletionView
            serviceName={currentRequest?.clientName || ""}
            email={email}
            emailValidated={emailValidation(email)}
            onEmailChange={this.handleEmailChange}
            onEnter={this.handleEnter}
          />
        );
    }
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentRequest: state.loginRequests.currentRequest,
  emailLoginState: state.firebaseAuthentication.emailLoginState,
  emailLoginLoadingState: state.firebaseAuthentication.emailLoginLoadingState,
  emailLoginCompleted: state.firebaseAuthentication.emailLoginCompleted,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  firebaseEmailLoginCheckState: (id: string) =>
    dispatch(firebaseEmailLoginCheckStateAction({ id })),
  firebaseEmailLoginCompletion: (email: string) =>
    dispatch(firebaseEmailLoginCompletionAction({ email })),
  completeLoginRequest: (id) => dispatch(completeLoginRequestAction({ id })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginEmailCompletionViewContainer);
