import { Theme } from "@mui/material";
import { createStyles, StyleRules } from "@mui/styles";

const avatarSize = 150;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: `${avatarSize}px`,
      height: `${avatarSize}px`,
      margin: `${theme.spacing(2)} auto`,
      cursor: "pointer",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      overflow: "hidden",
      "&:after": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#000000",
        content: "''",
        opacity: 0,
        transition: "opacity 300ms",
      },
      "&:hover": {
        "&:after": {
          opacity: 0.3,
        },
      },
    },
    avatarImage: {
      width: "100%",
      height: "100%",
    },
  });

export default styles;
