import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { replace } from "connected-react-router";
import { Formik } from "formik";
import GroupInvite from "../../../components/user/groups/GroupInvite";
import { LoadableValue, LoadingState } from "../../../modules/models";
import {
  getGroupAction,
  GroupData,
  GroupInviteFormData,
  inviteToGroupAction,
} from "../../../modules/groups";
import { selectIsGroupOwner } from "../../../modules/groups/selectors";
import { RootState } from "../../../modules";
import UserLoadableValueContainer from "../UserLoadableValueContainer";
import UserLoadingStateViewContainer from "../UserLoadingStateViewContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  groupId: string;
  userId?: string;
  group: LoadableValue<GroupData | null>;
  operationLoadingState: LoadingState;
  isOwner: boolean;
}

interface DispatchProps {
  backToGroup: (groupId: string) => void;
  getGroup: (groupId: string, userId: string) => void;
  inviteToGroup: (id: string, email: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class GroupInviteContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    this.loadGroupIfNeeded(undefined);
    this.checkIsOwner(undefined);
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { operationLoadingState } = this.props;
    this.loadGroupIfNeeded(prevProps);
    this.checkIsOwner(prevProps);
    if (
      operationLoadingState !== prevProps.operationLoadingState &&
      operationLoadingState === LoadingState.Initial
    ) {
      this.submitCompletion();
    }
  }

  private checkIsOwner = (prevProps: Readonly<Props> | undefined) => {
    const { groupId, group, isOwner, backToGroup } = this.props;
    if (prevProps?.group.value === group.value) {
      return;
    }
    if (
      group.loadingState === LoadingState.Initial &&
      group.value &&
      !isOwner
    ) {
      backToGroup(groupId);
    }
  };

  private handleSubmit = (values: GroupInviteFormData) => {
    const { groupId, inviteToGroup } = this.props;
    if (!groupId) {
      return;
    }
    inviteToGroup(groupId, values.email);
  };

  private handleBack = () => {
    const { groupId, backToGroup } = this.props;
    if (!groupId) {
      return;
    }
    backToGroup(groupId);
  };

  private loadGroupIfNeeded(prevProps?: Readonly<Props>) {
    const { group, groupId, userId, getGroup } = this.props;
    if (!userId || userId === prevProps?.userId) {
      return;
    }
    if (
      group.loadingState !== LoadingState.Initial ||
      group.value !== undefined
    ) {
      return;
    }
    getGroup(groupId, userId);
  }

  private submitCompletion() {
    const { groupId, backToGroup } = this.props;
    if (!groupId) {
      return;
    }
    backToGroup(groupId);
  }

  public render(): JSX.Element {
    const { group, operationLoadingState } = this.props;
    if (operationLoadingState === LoadingState.Loading) {
      return <UserLoadingStateViewContainer />;
    }
    return (
      <UserLoadableValueContainer value={group}>
        <Formik initialValues={{ email: "" }} onSubmit={this.handleSubmit}>
          {(formikProps) =>
            group.value && (
              <GroupInvite
                formikProps={formikProps}
                group={group.value}
                onBack={this.handleBack}
              />
            )
          }
        </Formik>
      </UserLoadableValueContainer>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: ExportProps & RouteComponentProps<{ id: string }>,
): StateProps => ({
  groupId: ownProps.match.params.id,
  userId: state.userProfile.current?.id,
  group: state.groups.group,
  operationLoadingState: state.groups.groupOperationLoadingState,
  isOwner: selectIsGroupOwner(state, ownProps.match.params.id),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  backToGroup: (groupId) => dispatch(replace(`/user/groups/${groupId}`)),
  getGroup: (groupId, userId) =>
    dispatch(getGroupAction({ id: groupId, userId })),
  inviteToGroup: (id, email) => dispatch(inviteToGroupAction({ id, email })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupInviteContainer);
