import {
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
  Store,
} from "redux";
import logger from "redux-logger";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import firebaseAuthenticationReducer from "./modules/firebaseAuthentication";
import firebaseAuthenticationSaga from "./modules/firebaseAuthentication/saga";
import loginRequestsReducer from "./modules/loginRequests";
import loginRequestsSaga from "./modules/loginRequests/saga";
import userProfileReducer from "./modules/userProfile";
import userProfileSaga from "./modules/userProfile/saga";
import groupsReducer from "./modules/groups";
import groupsSaga from "./modules/groups/saga";
import invitationsReducer from "./modules/invitations";
import invitationsSaga from "./modules/invitations/saga";
import windowsReducer from "./modules/windows";
import windowsSaga from "./modules/windows/saga";

const sagaMiddleware = createSagaMiddleware();
function* rootSaga(): Generator {
  yield all([
    firebaseAuthenticationSaga(),
    loginRequestsSaga(),
    userProfileSaga(),
    groupsSaga(),
    invitationsSaga(),
    windowsSaga(),
  ]);
}

export const history = createBrowserHistory();

const createStore = (): Store => {
  const reducer = combineReducers({
    router: connectRouter(history),
    firebaseAuthentication: firebaseAuthenticationReducer,
    loginRequests: loginRequestsReducer,
    userProfile: userProfileReducer,
    groups: groupsReducer,
    invitations: invitationsReducer,
    windows: windowsReducer,
  });
  const middleware = applyMiddleware(
    sagaMiddleware,
    routerMiddleware(history),
    logger,
  );
  const store = reduxCreateStore(reducer, middleware);
  const sagaTask = sagaMiddleware.run(rootSaga);
  sagaTask.toPromise().catch(() => {
    /* no op */
  });

  return store;
};

export default createStore;
