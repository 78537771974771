import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import classNames from "classnames";
import GroupIcon from "@mui/icons-material/Group";
import styles from "./GroupListStyles";
import PageTitle from "../../parts/PageTitle";
import MenuBox from "../../parts/MenuBox";
import { Group } from "../../../modules/groups";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  groups: Group[];
  onNew: () => void;
  onBack: () => void;
  onSelect: (group: Group) => void;
}

const GroupListItem: React.FC<
  { group: Group; onClick: () => void } & WithStyles<typeof styles>
> = ({ group, onClick, classes }) => (
  <ListItem disableGutters>
    <ListItemButton className={classes.listItemButton} onClick={onClick}>
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText
        primary={group.name}
        primaryTypographyProps={{ noWrap: true }}
      />
    </ListItemButton>
  </ListItem>
);

const GroupList: React.FC<Props> = ({
  groups,
  onSelect,
  onNew,
  onBack,
  classes,
  t,
}) => (
  <MenuBox>
    <PageTitle>{t("Group List")}</PageTitle>
    <div className={classes.list}>
      <List>
        {groups.map((group) => (
          <GroupListItem
            key={group.id}
            group={group}
            classes={classes}
            onClick={() => onSelect(group)}
          />
        ))}
      </List>
    </div>
    <Button
      className={classNames(classes.menuButton)}
      fullWidth
      onClick={onNew}
    >
      {t("New Group")}
    </Button>
    <Button
      className={classNames(classes.menuButton, classes.backButton)}
      fullWidth
      onClick={onBack}
    >
      {t("Back to Menu")}
    </Button>
  </MenuBox>
);

export default withTranslation()(withStyles(styles)(GroupList));
