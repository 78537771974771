import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import LoginEmailCompletionViewContainer from "./email/LoginEmailCompletionViewContainer";
import LoginEmailInputViewContainer from "./email/LoginEmailInputViewContainer";
import LoginRequestStatesContainer from "./LoginRequestStatesContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class LoginRouterContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <Switch>
        <Route
          exact
          path="/login/:id/email/completion"
          component={LoginEmailCompletionViewContainer}
        />
        <Route
          exact
          path="/login/:id/email"
          component={LoginEmailInputViewContainer}
        />
        <Route
          exact
          path="/login/:id"
          component={LoginRequestStatesContainer}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginRouterContainer);
