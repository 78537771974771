import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { ConnectedRouter } from "connected-react-router";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import createStore, { history } from "./configureState";
import RootContainer from "./containers/RootContainer";
import Router from "./containers/Router";
import { setupAuthentication, setupFirebase } from "./modules/firebase";
import "./modules/i18n";

interface Props {
  firebaseConfig: Record<string, string>;
}

const store = createStore();
const theme = createTheme();

class App extends React.PureComponent<Props> {
  public componentDidMount(): void {
    const { firebaseConfig } = this.props;
    setupFirebase(firebaseConfig);
    setupAuthentication(store);
  }

  public render(): JSX.Element {
    return (
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <Suspense fallback={null}>
                <RootContainer>
                  <Router />
                </RootContainer>
              </Suspense>
            </ThemeProvider>
          </ConnectedRouter>
        </StyledEngineProvider>
      </Provider>
    );
  }
}

export default App;
