import { Theme } from "@mui/material";
import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    inputBox: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    providerButton: {
      marginBottom: theme.spacing(1),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    googleButton: {
      backgroundColor: "#FFFFFF",
      color: "rgba(0,0,0,0.54)",
      textTransform: "none",
    },
    googleIcon: {
      marginRight: theme.spacing(2),
    },
  });

export default styles;
