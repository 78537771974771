import * as React from "react";
import { LoadableValue, LoadingState } from "../../modules/models";
import UserLoadingStateViewContainer from "./UserLoadingStateViewContainer";
import UserErrorStateViewContainer from "./UserErrorStateViewContainer";

type Props<T> = {
  value: LoadableValue<T>;
  children: ((value: T) => JSX.Element | null | undefined) | JSX.Element;
};

const UserLoadableValueContainer = <T extends unknown>(
  props: Props<T>,
): JSX.Element => {
  const { value, children } = props;
  if (
    value.loadingState === LoadingState.Loading ||
    value.value === undefined
  ) {
    return <UserLoadingStateViewContainer />;
  }
  if (value.loadingState === LoadingState.Error) {
    return <UserErrorStateViewContainer />;
  }
  if (typeof children === "function") {
    return children(value.value) as JSX.Element;
  }
  return children;
};

export default UserLoadableValueContainer;
