import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import styles from "./GroupDetailMemberContextMenuStyles";
import { MetaUser } from "../../../../../functions/src/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  user: MetaUser | null;
  onPromoteToOwner: (user: MetaUser) => void;
  onRemove: (user: MetaUser) => void;
  onClose: () => void;
}

const GroupDetailMemberContextMenu: React.FC<Props> = ({
  open,
  user,
  onPromoteToOwner,
  onRemove,
  onClose,
  t,
}) => (
  <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle>{user?.name}</DialogTitle>
    <List sx={{ pt: 0 }}>
      <ListItem button onClick={() => user && onPromoteToOwner(user)}>
        <ListItemText>{t("Promote to Owner")}</ListItemText>
      </ListItem>
      <ListItem button onClick={() => user && onRemove(user)}>
        <ListItemText>{t("Remove from Group")}</ListItemText>
      </ListItem>
      <ListItem button onClick={onClose}>
        <ListItemText>{t("Cancel")}</ListItemText>
      </ListItem>
    </List>
  </Dialog>
);

export default withTranslation()(
  withStyles(styles)(GroupDetailMemberContextMenu),
);
