import { SagaIterator } from "redux-saga";
import { call, takeEvery } from "typed-redux-saga";
import {
  getInvitationAction,
  getInvitationProgressAction,
  GetInvitationResult,
  InvitationReplyType,
  replyGroupInvitationAction,
  replyGroupInvitationProgressAction,
} from "./index";
import { getApiFunctionsInstanceAsync } from "../firebase";
import { bindAsyncTriggerAction } from "../sagaHelpers";

const getInvitationSaga = bindAsyncTriggerAction(
  getInvitationProgressAction,
  function* progressAction(payload) {
    const functions = yield* call(getApiFunctionsInstanceAsync);
    return (yield* call(
      functions.getInvitationAsync,
      payload.verificationId,
    )) as GetInvitationResult;
  },
);

const replyGroupInvitationSaga = bindAsyncTriggerAction(
  replyGroupInvitationProgressAction,
  function* progressAction(payload) {
    const functions = yield* call(getApiFunctionsInstanceAsync);
    if (payload.reply === InvitationReplyType.Accept) {
      yield* call(
        functions.acceptGroupInvitationAsync,
        payload.groupId,
        payload.invitationId,
        payload.verificationId,
      );
    } else {
      yield* call(
        functions.declineGroupInvitationAsync,
        payload.groupId,
        payload.invitationId,
        payload.verificationId,
      );
    }
  },
);

export default function* watchInvitations(): SagaIterator {
  yield* takeEvery(getInvitationAction.type, getInvitationSaga);
  yield* takeEvery(replyGroupInvitationAction.type, replyGroupInvitationSaga);
}
