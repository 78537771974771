import { call, put, takeEvery } from "typed-redux-saga";
import { SagaIterator } from "redux-saga";
import { Action } from "typescript-fsa";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  finishEmailSignIn,
  startEmailSignIn,
  startGoogleSignInAsync,
  startSignOutAsync,
} from "../firebase";
import { saveCurrentLoginRequestAction } from "../loginRequests";
import { watchUserProfileAction } from "../userProfile";
import {
  changeFirebaseUserAction,
  ChangeFirebaseUserActionParameters,
  FirebaseAuthenticationEmailLoginState,
  firebaseEmailLoginCheckStateAction,
  firebaseEmailLoginCheckStateCompletionAction,
  FirebaseEmailLoginCheckStateParameter,
  firebaseEmailLoginCompletionAction,
  FirebaseEmailLoginCompletionParameter,
  firebaseEmailLoginCompletionProgressAction,
  firebaseEmailLoginRequestAction,
  FirebaseEmailLoginRequestParameter,
  firebaseEmailLoginRequestProgressAction,
  requestFirebaseGoogleSignInAction,
  requestFirebaseSignOutAction,
} from "./index";

const lastEmailLoginKey = "CtimeID.LastEmailLogin";

function* changeFirebaseUserSaga(
  action: Action<ChangeFirebaseUserActionParameters>,
) {
  yield* put(watchUserProfileAction({ userId: action.payload.userDataId }));
}

function* requestGoogleSignInSaga() {
  yield* put(saveCurrentLoginRequestAction());
  yield* call(startGoogleSignInAsync);
}

function* requestSignOutSaga() {
  // yield* put(setUserInformation({ userInformation: undefined }));
  yield* put(watchUserProfileAction({ userId: undefined }));
  yield* call(startSignOutAsync);
}

const firebaseEmailLoginRequestProgressSaga = bindAsyncAction(
  firebaseEmailLoginRequestProgressAction,
)(function* emailLoginRequestProgressSagaAction(payload) {
  const { host, protocol } = window.location;
  const url = `${protocol}//${host}/login/${payload.id}/email/completion`;
  window.localStorage.setItem(
    lastEmailLoginKey,
    JSON.stringify({
      id: payload.id,
      email: payload.email,
    }),
  );
  yield* call(startEmailSignIn, url, payload.email);
});

function* firebaseEmailLoginRequestSaga(
  action: Action<FirebaseEmailLoginRequestParameter>,
) {
  yield* call(firebaseEmailLoginRequestProgressSaga, action.payload);
}

function* firebaseEmailLoginCheckStateSaga(
  action: Action<FirebaseEmailLoginCheckStateParameter>,
) {
  const { id } = action.payload;
  const lastLoginStateJson = window.localStorage.getItem(lastEmailLoginKey);
  let loginState: FirebaseAuthenticationEmailLoginState = { id };
  if (lastLoginStateJson) {
    const lastLoginState = JSON.parse(
      lastLoginStateJson,
    ) as FirebaseAuthenticationEmailLoginState;
    if (lastLoginState.id === loginState.id) {
      loginState = lastLoginState;
    }
  }
  yield* put(firebaseEmailLoginCheckStateCompletionAction(loginState));
}

const firebaseEmailLoginCompletionProgressSaga = bindAsyncAction(
  firebaseEmailLoginCompletionProgressAction,
)(function* firebaseEmailLoginCompletionProgressSagaAction(payload) {
  yield* call(finishEmailSignIn, payload.email, window.location.href);
  window.localStorage.removeItem(lastEmailLoginKey);
});

function* firebaseEmailLoginCompletionSaga(
  action: Action<FirebaseEmailLoginCompletionParameter>,
) {
  yield* call(firebaseEmailLoginCompletionProgressSaga, action.payload);
}

export default function* watchAuthentication(): SagaIterator {
  yield* takeEvery(changeFirebaseUserAction.type, changeFirebaseUserSaga);
  yield* takeEvery(
    requestFirebaseGoogleSignInAction.type,
    requestGoogleSignInSaga,
  );
  yield* takeEvery(requestFirebaseSignOutAction.type, requestSignOutSaga);
  yield* takeEvery(
    firebaseEmailLoginRequestAction.type,
    firebaseEmailLoginRequestSaga,
  );
  yield* takeEvery(
    firebaseEmailLoginCheckStateAction.type,
    firebaseEmailLoginCheckStateSaga,
  );
  yield* takeEvery(
    firebaseEmailLoginCompletionAction.type,
    firebaseEmailLoginCompletionSaga,
  );
}
