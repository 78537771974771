import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import InvitationLoginRequestView from "../../components/invitations/InvitationLoginRequestView";
import {
  clearIdpMenuLoginAction,
  createIdpMenuLoginAction,
  IdpMenuLoginResult,
} from "../../modules/loginRequests";
import { storeBackPathAction } from "../../modules/windows";
import { RootState } from "../../modules";
import { LoadableValue, LoadingState } from "../../modules/models";
import InvitationLoadingStateViewContainer from "./InvitationLoadingStateViewContainer";
import InvitationErrorStateViewContainer from "./InvitationErrorStateViewContainer";
import { InvitationErrorReason } from "../../modules/invitations";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {
  verificationId: string;
}

interface StateProps {
  idpMenuLoginRequest: LoadableValue<IdpMenuLoginResult>;
}

interface DispatchProps {
  createIdpMenuLogin: () => void;
  clearIdpMenuLogin: () => void;
  navigateToLogin: (id: string) => void;
  storeBackPath: (path: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class InvitationLoginRequestViewContainer extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Readonly<Props>) {
    this.navigateToLoginIfNeeded(prevProps);
  }

  private handleOnLogin = () => {
    const { createIdpMenuLogin, storeBackPath, verificationId } = this.props;
    storeBackPath(`/invitations/${verificationId}`);
    createIdpMenuLogin();
  };

  private navigateToLoginIfNeeded(prevProps: Readonly<Props>) {
    const { idpMenuLoginRequest, clearIdpMenuLogin, navigateToLogin } =
      this.props;
    if (idpMenuLoginRequest.value && !prevProps.idpMenuLoginRequest.value) {
      clearIdpMenuLogin();
      navigateToLogin(idpMenuLoginRequest.value.id);
    }
  }

  public render(): JSX.Element {
    const { idpMenuLoginRequest } = this.props;
    switch (idpMenuLoginRequest.loadingState) {
      case LoadingState.Loading:
        return <InvitationLoadingStateViewContainer />;
      case LoadingState.Error:
        return (
          <InvitationErrorStateViewContainer
            reason={InvitationErrorReason.Generic}
          />
        );
      default:
        return <InvitationLoginRequestView onLogin={this.handleOnLogin} />;
    }
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  idpMenuLoginRequest: state.loginRequests.idpMenuLoginRequest,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigateToLogin: (id: string) => dispatch(push(`/login/${id}`)),
  createIdpMenuLogin: () => dispatch(createIdpMenuLoginAction()),
  clearIdpMenuLogin: () => dispatch(clearIdpMenuLoginAction()),
  storeBackPath: (path: string) => dispatch(storeBackPathAction({ path })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationLoginRequestViewContainer);
