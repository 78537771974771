import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import classNames from "classnames";
import EmailIcon from "@mui/icons-material/Email";
import { WithT } from "i18next";
import styles from "./GroupDetailStyles";
import MenuBox from "../../parts/MenuBox";
import PageTitle from "../../parts/PageTitle";
import PageSectionTitle from "../../parts/PageSectionTitle";
import { GroupData, GroupInvitation } from "../../../modules/groups";
import { MetaUser } from "../../../../functions/src/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  group: GroupData;
  isOwner: boolean;
  owners: MetaUser[];
  members: MetaUser[];
  onOwnerClick: (user: MetaUser) => void;
  onMemberClick: (user: MetaUser) => void;
  onInvitationClick: (invitation: GroupInvitation) => void;
  onEdit: () => void;
  onInvite: () => void;
  onBack: () => void;
}

const UserListItem: React.FC<{ metaUser: MetaUser; onClick: () => void }> = ({
  metaUser,
  onClick,
}) => (
  <ListItem>
    <ListItemButton onClick={onClick}>
      <ListItemAvatar>
        <Avatar src={metaUser.avatarUrl} />
      </ListItemAvatar>
      <ListItemText>{metaUser.name}</ListItemText>
    </ListItemButton>
  </ListItem>
);

const InvitationListItem: React.FC<
  {
    invitation: GroupInvitation;
    onClick: () => void;
  } & WithT
> = ({ invitation, onClick, t }) => (
  <ListItem>
    <ListItemButton onClick={onClick}>
      <ListItemAvatar>
        <Avatar>
          <EmailIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={invitation.email}
        secondary={t(`Invitation Status ${invitation.status}`)}
      />
    </ListItemButton>
  </ListItem>
);

const GroupDetail: React.FC<Props> = ({
  group,
  isOwner,
  owners,
  members,
  classes,
  onEdit,
  onBack,
  onInvite,
  onOwnerClick,
  onMemberClick,
  onInvitationClick,
  t,
}) => (
  <MenuBox>
    <PageTitle>{t("Group Detail")}</PageTitle>
    <PageSectionTitle>{group.group.name}</PageSectionTitle>
    <div className={classes.userList}>
      <List
        disablePadding
        subheader={
          <ListSubheader disableGutters>{t("Group Owners")}</ListSubheader>
        }
      >
        {owners.map((v) => (
          <UserListItem
            key={v.id}
            metaUser={v}
            onClick={() => onOwnerClick(v)}
          />
        ))}
      </List>
      {members.length > 0 && (
        <List
          disablePadding
          subheader={
            <ListSubheader disableGutters>{t("Group Members")}</ListSubheader>
          }
        >
          {members.map((v) => (
            <UserListItem
              key={v.id}
              metaUser={v}
              onClick={() => onMemberClick(v)}
            />
          ))}
        </List>
      )}
      {isOwner && group.invitations.length > 0 && (
        <List
          disablePadding
          subheader={
            <ListSubheader disableGutters>
              {t("Invited Group Members")}
            </ListSubheader>
          }
        >
          {group.invitations.map((v) => (
            <InvitationListItem
              key={v.id}
              invitation={v}
              onClick={() => onInvitationClick(v)}
              t={t}
            />
          ))}
        </List>
      )}
    </div>
    <div className={classes.menuItems}>
      {isOwner && (
        <>
          <Button
            className={classes.menuButton}
            fullWidth
            variant="outlined"
            onClick={onInvite}
          >
            {t("Group Invite Member")}
          </Button>
          <Button
            className={classes.menuButton}
            fullWidth
            variant="outlined"
            onClick={onEdit}
          >
            {t("Edit Group")}
          </Button>
        </>
      )}
      <Button
        className={classNames(classes.menuButton, classes.backButton)}
        fullWidth
        onClick={onBack}
      >
        {t("Back to Groups")}
      </Button>
    </div>
  </MenuBox>
);

export default withTranslation()(withStyles(styles)(GroupDetail));
