import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import InvitationPage from "../../components/invitations/InvitationPage";
import { LoadableValue, LoadingState } from "../../modules/models";
import { FirebaseAuthenticationStatus } from "../../modules/firebaseAuthentication";
import {
  getInvitationAction,
  GetInvitationResult,
  InvitationErrorReason,
  InvitationReplyType,
} from "../../modules/invitations";
import { RootState } from "../../modules";
import InvitationLoadingStateViewContainer from "./InvitationLoadingStateViewContainer";
import InvitationErrorStateViewContainer from "./InvitationErrorStateViewContainer";
import InvitationGroupViewContainer from "./group/InvitationGroupViewContainer";
import InvitationLoginRequestViewContainer from "./InvitationLoginRequestViewContainer";
import InvitationReplyResultViewContainer from "./InvitationReplyResultViewContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {
  match: { params: { id: string } };
}

interface StateProps {
  invitation: LoadableValue<GetInvitationResult>;
  invitationReply: LoadableValue<InvitationReplyType>;
  authenticationStatus: FirebaseAuthenticationStatus;
}

interface DispatchProps {
  getInvitationRequest: (id: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class InvitationPageContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    const { match, getInvitationRequest } = this.props;
    const { id } = match.params;
    if (!id) {
      return;
    }
    getInvitationRequest(id);
  }

  private view(): JSX.Element {
    const { invitation, invitationReply, authenticationStatus } = this.props;
    if (
      !invitation.value ||
      invitation.loadingState === LoadingState.Loading ||
      invitationReply.loadingState === LoadingState.Loading
    ) {
      return <InvitationLoadingStateViewContainer />;
    }
    if (
      invitation.loadingState === LoadingState.Error ||
      invitationReply.loadingState === LoadingState.Error
    ) {
      return (
        <InvitationErrorStateViewContainer
          reason={InvitationErrorReason.Generic}
        />
      );
    }
    switch (authenticationStatus) {
      case FirebaseAuthenticationStatus.Initial:
        return <InvitationLoadingStateViewContainer />;
      case FirebaseAuthenticationStatus.NotAuthenticated:
        return (
          <InvitationLoginRequestViewContainer
            verificationId={invitation.value.verificationId}
          />
        );
      default:
        break;
    }
    if (invitationReply.value) {
      return <InvitationReplyResultViewContainer />;
    }
    switch (invitation.value.type) {
      case "group":
        return <InvitationGroupViewContainer />;
      case "expired":
        return (
          <InvitationErrorStateViewContainer
            reason={InvitationErrorReason.Expired}
          />
        );
      default:
        return (
          <InvitationErrorStateViewContainer
            reason={InvitationErrorReason.NotFound}
          />
        );
    }
  }

  public render(): JSX.Element {
    return <InvitationPage>{this.view()}</InvitationPage>;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  invitation: state.invitations.invitation,
  invitationReply: state.invitations.invitationReply,
  authenticationStatus: state.firebaseAuthentication.status,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getInvitationRequest: (id) =>
    dispatch(getInvitationAction({ verificationId: id })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationPageContainer);
