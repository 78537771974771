import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Container, Paper } from "@mui/material";
import styles from "./PageContainerStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  paper?: boolean;
}

const PageContainer: React.FC<Props> = ({ paper, classes, children }) => (
  <Container component="main" maxWidth="xs">
    {paper === true ? (
      <Paper className={classes.componentContainer}>{children}</Paper>
    ) : (
      children
    )}
  </Container>
);

export default withTranslation()(withStyles(styles)(PageContainer));
