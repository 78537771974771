import { WithStyles } from "@mui/styles";
import { withTranslation, WithTranslation } from "react-i18next";
import * as React from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "./MenuBoxStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const MenuBox: React.FC<Props> = ({ children, classes }) => (
  <div className={classes.loginBox}>{children}</div>
);

export default withTranslation()(withStyles(styles)(MenuBox));
