import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {},
    loginBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  });

export default styles;
