import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoginConfirmView from "../../components/login/LoginConfirmView";
import LoginLoadingStateView from "../../components/login/LoginLoadingStateView";
import { RootState } from "../../modules";
import { requestFirebaseSignOutAction } from "../../modules/firebaseAuthentication";
import {
  completeLoginRequestAction,
  LoginRequest,
} from "../../modules/loginRequests";
import { UserProfile } from "../../modules/userProfile";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  currentRequest?: LoginRequest;
  lastLoginRequestId?: string;
  userProfile?: UserProfile;
  emailLoginCompleted: boolean;
}

interface DispatchProps {
  requestFirebaseSignOut: () => void;
  completeLoginRequest: (id: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class LoginConfirmViewContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    const { currentRequest, lastLoginRequestId } = this.props;
    if (currentRequest && currentRequest?.id === lastLoginRequestId) {
      this.handleFinishLogin();
    }
  }

  private handleFinishLogin = () => {
    const { currentRequest, completeLoginRequest } = this.props;
    if (!currentRequest?.id) {
      return;
    }
    completeLoginRequest(currentRequest.id);
  };

  private handleOtherLogin = () => {
    const { requestFirebaseSignOut } = this.props;
    requestFirebaseSignOut();
  };

  public render(): JSX.Element {
    const { currentRequest, lastLoginRequestId, userProfile } = this.props;
    if (currentRequest && currentRequest?.id === lastLoginRequestId) {
      return <LoginLoadingStateView />;
    }
    return (
      <LoginConfirmView
        serviceName={currentRequest?.clientName || ""}
        userName={userProfile?.name || ""}
        photoUrl={userProfile?.avatarUrl || ""}
        onFinishLogin={this.handleFinishLogin}
        onOtherLogin={this.handleOtherLogin}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentRequest: state.loginRequests.currentRequest,
  lastLoginRequestId: state.loginRequests.lastLoginRequestId,
  emailLoginCompleted: state.firebaseAuthentication.emailLoginCompleted,
  userProfile: state.userProfile.current,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  requestFirebaseSignOut: () => dispatch(requestFirebaseSignOutAction()),
  completeLoginRequest: (id) => dispatch(completeLoginRequestAction({ id })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginConfirmViewContainer);
