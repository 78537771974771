import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Avatar, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import styles from "./UserAvatarViewStyles";
import { UserProfile } from "../../modules/userProfile";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  userProfile?: UserProfile;
}

const UserAvatarView: React.FC<Props> = ({ userProfile, classes }) => {
  if (!userProfile) {
    return null;
  }
  const { avatarUrl, name } = userProfile;
  return (
    <div className={classes.avatarContainer}>
      <Avatar>
        {avatarUrl ? (
          <img className={classes.avatarImage} src={avatarUrl} alt={name} />
        ) : (
          <LockOutlinedIcon />
        )}
      </Avatar>
      <Typography className={classes.userName} variant="h6">
        {name}
      </Typography>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(UserAvatarView));
