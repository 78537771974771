import { Theme } from "@mui/material";
import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
    },
  });

export default styles;
