import * as React from "react";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import { Dispatch } from "redux";
import GroupList from "../../../components/user/groups/GroupList";
import { Group, watchGroupsAction } from "../../../modules/groups";
import { RootState } from "../../../modules";
import { LoadableValue } from "../../../modules/models";
import UserLoadableValueContainer from "../UserLoadableValueContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  userId?: string;
  groups: LoadableValue<Group[]>;
}

interface DispatchProps {
  watchGroups: (userId?: string) => void;
  openGroupDetail: (groupId: string) => void;
  showNew: () => void;
  backToMenu: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class GroupListContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    const { watchGroups, userId } = this.props;
    watchGroups(userId);
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { watchGroups, userId } = this.props;
    if (userId !== prevProps.userId) {
      watchGroups(userId);
    }
  }

  public componentWillUnmount() {
    const { watchGroups } = this.props;
    watchGroups();
  }

  private handleSelect = (group: Group) => {
    const { openGroupDetail } = this.props;
    openGroupDetail(group.id);
  };

  public render(): JSX.Element {
    const { groups, backToMenu, showNew } = this.props;
    return (
      <UserLoadableValueContainer value={groups}>
        {(value) => (
          <GroupList
            groups={value || []}
            onBack={backToMenu}
            onNew={showNew}
            onSelect={this.handleSelect}
          />
        )}
      </UserLoadableValueContainer>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  userId: state.userProfile.current?.id,
  groups: state.groups.groups,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  backToMenu: () => dispatch(replace("/user")),
  watchGroups: (userId) => dispatch(watchGroupsAction({ userId })),
  showNew: () => dispatch(push(`/user/groups/new`)),
  openGroupDetail: (groupId) => dispatch(push(`/user/groups/${groupId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupListContainer);
