import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./UserPageStyles";
import PageContainer from "../parts/PageContainer";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const UserPage: React.FC<Props> = ({ children }) => (
  <PageContainer paper>{children}</PageContainer>
);

export default withTranslation()(withStyles(styles)(UserPage));
