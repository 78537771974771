import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import InvitationGroupView from "../../../components/invitations/group/InvitationGroupView";
import { RootState } from "../../../modules";
import { LoadableValue } from "../../../modules/models";
import {
  GetInvitationGroupResult,
  InvitationReplyType,
  replyGroupInvitationAction,
} from "../../../modules/invitations";
import { UserProfile } from "../../../modules/userProfile";
import { requestFirebaseSignOutAction } from "../../../modules/firebaseAuthentication";
import { createIdpMenuLoginAction } from "../../../modules/loginRequests";
import { storeBackPathAction } from "../../../modules/windows";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  invitation: LoadableValue<GetInvitationGroupResult>;
  userProfile?: UserProfile;
}

interface DispatchProps {
  storeBackPath: (path: string) => void;
  createIdpMenuLogin: () => void;
  requestFirebaseSignOut: () => void;
  replyGroupInvitation: (
    groupId: string,
    invitationId: string,
    verificationId: string,
    reply: InvitationReplyType,
  ) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class InvitationGroupViewContainer extends React.PureComponent<Props> {
  private sendReply = (reply: InvitationReplyType) => {
    const { invitation, replyGroupInvitation } = this.props;
    if (!invitation.value) {
      return;
    }
    const { invitationId, group, verificationId } = invitation.value;
    replyGroupInvitation(group.id, invitationId, verificationId, reply);
  };

  private handleOnAccept = () => this.sendReply(InvitationReplyType.Accept);
  private handleOnDecline = () => this.sendReply(InvitationReplyType.Decline);

  private handleOnSwitchAccount = () => {
    const {
      requestFirebaseSignOut,
      createIdpMenuLogin,
      storeBackPath,
      invitation,
    } = this.props;
    const verificationId = invitation.value?.verificationId;
    if (!verificationId) {
      return;
    }
    requestFirebaseSignOut();
    storeBackPath(`/invitations/${verificationId}`);
    createIdpMenuLogin();
  };

  public render(): JSX.Element {
    const { invitation, userProfile } = this.props;
    return (
      <InvitationGroupView
        userProfile={userProfile}
        groupName={invitation.value?.group.name ?? ""}
        onAccept={this.handleOnAccept}
        onSwitchAccount={this.handleOnSwitchAccount}
        onDecline={this.handleOnDecline}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  invitation: state.invitations
    .invitation as LoadableValue<GetInvitationGroupResult>,
  userProfile: state.userProfile.current,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createIdpMenuLogin: () => dispatch(createIdpMenuLoginAction()),
  storeBackPath: (path: string) => dispatch(storeBackPathAction({ path })),
  requestFirebaseSignOut: () => dispatch(requestFirebaseSignOutAction()),
  replyGroupInvitation: (groupId, invitationId, verificationId, reply) =>
    dispatch(
      replyGroupInvitationAction({
        groupId,
        invitationId,
        verificationId,
        reply,
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationGroupViewContainer);
