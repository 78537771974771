import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { push, replace } from "connected-react-router";
import GroupDetail from "../../../components/user/groups/GroupDetail";
import { RootState } from "../../../modules";
import {
  cancelInvitationToGroupAction,
  changeMemberRoleInGroupAction,
  GroupData,
  GroupInvitation,
  GroupUserMetaData,
  removeMemberFromGroupAction,
  UserRole,
  watchGroupDetailAction,
} from "../../../modules/groups";
import { LoadableValue } from "../../../modules/models";
import UserLoadableValueContainer from "../UserLoadableValueContainer";
import {
  selectGroupUserMetaData,
  selectIsGroupOwner,
} from "../../../modules/groups/selectors";
import { selectCurrentUser } from "../../../modules/userProfile/selectors";
import { UserProfile } from "../../../modules/userProfile";
import { MetaUser } from "../../../../functions/src/models";
import GroupDetailOwnerContextMenu from "../../../components/user/groups/detail/GroupDetailOwnerContextMenu";
import GroupDetailMemberContextMenu from "../../../components/user/groups/detail/GroupDetailMemberContextMenu";
import GroupDetailInvitationContextMenu from "../../../components/user/groups/detail/GroupDetailInvitationContextMenu";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  user?: UserProfile;
  groupId: string;
  group: LoadableValue<GroupData | null>;
  userMetaData: GroupUserMetaData;
  isOwner: boolean;
}

interface DispatchProps {
  watchGroupDetail: (userId?: string, groupId?: string) => void;
  backToList: () => void;
  goToEdit: (groupId: string) => void;
  goToInvite: (groupId: string) => void;
  cancelInvitationToGroup: (groupId: string, invitationId: string) => void;
  changeMemberRoleInGroup: (
    groupId: string,
    userId: string,
    role: UserRole,
  ) => void;
  removeMemberFromGroup: (groupId: string, userId: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

type State = {
  ownerOpen: boolean;
  selectedOwner: MetaUser | null;
  memberOpen: boolean;
  selectedMember: MetaUser | null;
  invitationOpen: boolean;
  selectedInvitation: GroupInvitation | null;
};

class GroupDetailContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = {
      ownerOpen: false,
      selectedOwner: null,
      memberOpen: false,
      selectedMember: null,
      invitationOpen: false,
      selectedInvitation: null,
    };
  }

  public componentDidMount() {
    this.requestLoad();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { user } = this.props;
    if (user?.id !== prevProps.user?.id) {
      this.requestLoad();
    }
  }

  public componentWillUnmount() {
    const { watchGroupDetail } = this.props;
    watchGroupDetail();
  }

  private closeContextMenu = () => {
    this.setState({
      memberOpen: false,
      ownerOpen: false,
      invitationOpen: false,
    });
  };

  private requestLoad = () => {
    const { groupId, user, watchGroupDetail } = this.props;
    if (!user) {
      return;
    }
    watchGroupDetail(user.id, groupId);
  };

  private handleOnEdit = () => {
    const { goToEdit, groupId } = this.props;
    goToEdit(groupId);
  };

  private handleOnInvite = () => {
    const { goToInvite, groupId } = this.props;
    goToInvite(groupId);
  };

  private handleOnInvitationCancel = (invitation: GroupInvitation) => {
    const { groupId, cancelInvitationToGroup } = this.props;
    cancelInvitationToGroup(groupId, invitation.id);
    this.closeContextMenu();
  };

  private handleOnRevoke = (user: MetaUser) => {
    const { groupId, changeMemberRoleInGroup } = this.props;
    changeMemberRoleInGroup(groupId, user.id, UserRole.Member);
    this.closeContextMenu();
  };

  private handleOnPromoteToOwner = (user: MetaUser) => {
    const { groupId, changeMemberRoleInGroup } = this.props;
    changeMemberRoleInGroup(groupId, user.id, UserRole.Owner);
    this.closeContextMenu();
  };

  private handleOnMemberRemove = (user: MetaUser) => {
    this.closeContextMenu();
    const { groupId, removeMemberFromGroup } = this.props;
    removeMemberFromGroup(groupId, user.id);
    this.closeContextMenu();
  };

  private handleOnBack = () => {
    const { backToList } = this.props;
    backToList();
  };

  public render(): JSX.Element {
    const { group, user, isOwner, userMetaData } = this.props;
    const {
      ownerOpen,
      selectedOwner,
      memberOpen,
      selectedMember,
      invitationOpen,
      selectedInvitation,
    } = this.state;
    const userId = user?.id ?? "";
    return (
      <>
        <UserLoadableValueContainer value={group}>
          {(value) =>
            value && (
              <GroupDetail
                group={value}
                isOwner={isOwner}
                owners={userMetaData.owners}
                members={userMetaData.members}
                onEdit={this.handleOnEdit}
                onInvite={this.handleOnInvite}
                onBack={this.handleOnBack}
                onOwnerClick={(v) =>
                  isOwner &&
                  this.setState({ selectedOwner: v, ownerOpen: true })
                }
                onMemberClick={(v) =>
                  isOwner &&
                  this.setState({ selectedMember: v, memberOpen: true })
                }
                onInvitationClick={(v) =>
                  isOwner &&
                  this.setState({ selectedInvitation: v, invitationOpen: true })
                }
              />
            )
          }
        </UserLoadableValueContainer>
        <GroupDetailOwnerContextMenu
          open={ownerOpen}
          user={selectedOwner}
          currentUserId={userId}
          onRevoke={this.handleOnRevoke}
          onRemove={this.handleOnMemberRemove}
          onClose={this.closeContextMenu}
        />
        <GroupDetailMemberContextMenu
          open={memberOpen}
          user={selectedMember}
          onPromoteToOwner={this.handleOnPromoteToOwner}
          onRemove={this.handleOnMemberRemove}
          onClose={this.closeContextMenu}
        />
        <GroupDetailInvitationContextMenu
          open={invitationOpen}
          invitation={selectedInvitation}
          onInvitationCancel={this.handleOnInvitationCancel}
          onClose={this.closeContextMenu}
        />
      </>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: RouteComponentProps<{ id: string }>,
): StateProps => ({
  user: selectCurrentUser(state),
  groupId: ownProps.match.params.id,
  group: state.groups.group,
  userMetaData: selectGroupUserMetaData(state, ownProps.match.params.id),
  isOwner: selectIsGroupOwner(state, ownProps.match.params.id),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  watchGroupDetail: (userId, groupId) =>
    dispatch(watchGroupDetailAction({ userId, groupId })),
  backToList: () => dispatch(replace("/user/groups/")),
  goToEdit: (groupId) => dispatch(push(`/user/groups/${groupId}/edit`)),
  goToInvite: (groupId) => dispatch(push(`/user/groups/${groupId}/invite`)),
  cancelInvitationToGroup: (groupId, invitationId) =>
    dispatch(cancelInvitationToGroupAction({ groupId, invitationId })),
  changeMemberRoleInGroup: (groupId, userId, role) =>
    dispatch(changeMemberRoleInGroupAction({ groupId, userId, role })),
  removeMemberFromGroup: (groupId, userId) =>
    dispatch(removeMemberFromGroupAction({ groupId, userId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupDetailContainer);
