import { Avatar, Button, Typography } from "@mui/material";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import styles from "./LoginConfirmViewStyles";
import MenuBox from "../parts/MenuBox";
import PageContainer from "../parts/PageContainer";
import PageTitle from "../parts/PageTitle";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  serviceName: string;
  userName: string;
  photoUrl: string;
  onFinishLogin: () => void;
  onOtherLogin: () => void;
}

const LoginConfirmView: React.FC<Props> = ({
  serviceName,
  userName,
  photoUrl,
  onFinishLogin,
  onOtherLogin,
  classes,
  t,
}) => (
  <PageContainer>
    <MenuBox>
      <PageTitle>{t("Sign In Title", { name: serviceName })}</PageTitle>
      <div className={classes.avatarContainer}>
        <Avatar>
          {photoUrl ? (
            <img
              className={classes.avatarImage}
              src={photoUrl}
              alt={userName}
            />
          ) : (
            <LockOutlinedIcon />
          )}
        </Avatar>
        <Typography
          className={classes.userName}
          component="caption"
          variant="h6"
        >
          {userName}
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          fullWidth
          variant="contained"
          onClick={onFinishLogin}
        >
          {t("Use this account")}
        </Button>
        <Button className={classes.button} fullWidth onClick={onOtherLogin}>
          {t("Use other account")}
        </Button>
      </div>
    </MenuBox>
  </PageContainer>
);

export default withTranslation()(withStyles(styles)(LoginConfirmView));
