import { Theme } from "@mui/material";
import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey.A100,
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  });

export default styles;
