import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    menuItems: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    menuButton: {
      marginTop: theme.spacing(1),
    },
    backButton: {
      marginTop: theme.spacing(2),
    },
    declineButton: {
      color: theme.palette.error.main,
    },
  });

export default styles;
