import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { Dispatch } from "redux";
import UserPage from "../../components/user/UserPage";
import { RootState } from "../../modules";
import { FirebaseAuthenticationStatus } from "../../modules/firebaseAuthentication";
import LoginLoadingStateViewContainer from "../login/LoginLoadingStateViewContainer";
import UserConfigurationMenuContainer from "./UserConfigurationMenuContainer";
import UserEditContainer from "./edit/UserEditContainer";
import GroupListContainer from "./groups/GroupListContainer";
import GroupDetailContainer from "./groups/GroupDetailContainer";
import GroupEditContainer from "./groups/GroupEditContainer";
import GroupInviteContainer from "./groups/GroupInviteContainer";
import {
  clearIdpMenuLoginAction,
  createIdpMenuLoginAction,
  IdpMenuLoginResult,
} from "../../modules/loginRequests";
import { LoadableValue } from "../../modules/models";
import { storeBackPathAction } from "../../modules/windows";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  firebaseAuthenticationStatus: FirebaseAuthenticationStatus;
  idpMenuLoginRequest: LoadableValue<IdpMenuLoginResult>;
  path: string;
}

interface DispatchProps {
  createIdpMenuLogin: () => void;
  clearIdpMenuLogin: () => void;
  navigateToLogin: (id: string) => void;
  storeBackPath: (path: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class UserPageContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    this.checkLoginState(undefined);
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    this.checkLoginState(prevProps);
    this.navigateToLoginIfNeeded(prevProps);
  }

  private checkLoginState = (prevProps: Readonly<Props> | undefined) => {
    const {
      firebaseAuthenticationStatus,
      createIdpMenuLogin,
      storeBackPath,
      path,
    } = this.props;
    if (
      firebaseAuthenticationStatus !==
        prevProps?.firebaseAuthenticationStatus &&
      firebaseAuthenticationStatus ===
        FirebaseAuthenticationStatus.NotAuthenticated
    ) {
      storeBackPath(path);
      createIdpMenuLogin();
    }
  };

  private navigateToLoginIfNeeded(prevProps: Readonly<Props>) {
    const { idpMenuLoginRequest, clearIdpMenuLogin, navigateToLogin } =
      this.props;
    if (idpMenuLoginRequest.value && !prevProps.idpMenuLoginRequest.value) {
      clearIdpMenuLogin();
      navigateToLogin(idpMenuLoginRequest.value.id);
    }
  }

  private contents(): JSX.Element {
    const { firebaseAuthenticationStatus } = this.props;
    if (
      firebaseAuthenticationStatus !==
      FirebaseAuthenticationStatus.Authenticated
    ) {
      return <LoginLoadingStateViewContainer />;
    }
    return (
      <Switch>
        <Route path="/user/groups/new" component={GroupEditContainer} />
        <Route path="/user/groups/:id/edit" component={GroupEditContainer} />
        <Route
          path="/user/groups/:id/invite"
          component={GroupInviteContainer}
        />
        <Route path="/user/groups/:id" component={GroupDetailContainer} />
        <Route path="/user/groups" component={GroupListContainer} />
        <Route path="/user/edit" component={UserEditContainer} />
        <Route component={UserConfigurationMenuContainer} />
      </Switch>
    );
  }

  public render(): JSX.Element {
    return <UserPage>{this.contents()}</UserPage>;
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: ExportProps & RouteComponentProps,
): StateProps => ({
  firebaseAuthenticationStatus: state.firebaseAuthentication.status,
  idpMenuLoginRequest: state.loginRequests.idpMenuLoginRequest,
  path: ownProps.location.pathname,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigateToLogin: (id: string) => dispatch(push(`/login/${id}`)),
  createIdpMenuLogin: () => dispatch(createIdpMenuLoginAction()),
  clearIdpMenuLogin: () => dispatch(clearIdpMenuLoginAction()),
  storeBackPath: (path: string) => dispatch(storeBackPathAction({ path })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPageContainer);
