import { replace } from "connected-react-router";
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";
import UserEdit from "../../../components/user/edit/UserEdit";
import { RootState } from "../../../modules";
import { LoadingState } from "../../../modules/models";
import {
  NewAvatarData,
  updateUserProfileAction,
  UserProfile,
  UserProfileFormData,
} from "../../../modules/userProfile";
import UserErrorStateViewContainer from "../UserErrorStateViewContainer";
import UserLoadingStateViewContainer from "../UserLoadingStateViewContainer";
import AvatarViewContainer from "./AvatarViewContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  userProfile?: UserProfile;
  newAvatarData?: NewAvatarData;
  updateLoadingState: LoadingState;
  updatedUserProfile: boolean;
}

interface DispatchProps {
  backToMenu: () => void;
  updateUserProfile: (form: UserProfileFormData, newAvatar: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
});

class UserEditContainer extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { updatedUserProfile, backToMenu } = this.props;
    if (
      prevProps.updatedUserProfile !== updatedUserProfile &&
      updatedUserProfile
    ) {
      backToMenu();
    }
  }

  private handleSubmit = (values: UserProfileFormData) => {
    const { updateUserProfile, newAvatarData } = this.props;
    updateUserProfile(values, newAvatarData?.fileId ?? "");
  };

  private renderUserEditForm() {
    const { backToMenu, userProfile } = this.props;
    if (!userProfile) {
      return null;
    }
    const initialValues: UserProfileFormData = {
      name: userProfile.name,
    };
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      >
        {(formikProps) => (
          <UserEdit
            formikProps={formikProps}
            avatar={<AvatarViewContainer />}
            onBack={backToMenu}
          />
        )}
      </Formik>
    );
  }

  public render(): React.ReactNode {
    const { updateLoadingState } = this.props;
    switch (updateLoadingState) {
      case LoadingState.Loading:
        return <UserLoadingStateViewContainer />;
      case LoadingState.Error:
        return <UserErrorStateViewContainer />;
      default:
        return this.renderUserEditForm();
    }
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  userProfile: state.userProfile.current,
  newAvatarData: state.userProfile.newAvatarData.value,
  updateLoadingState: state.userProfile.updateLoadingState,
  updatedUserProfile: state.userProfile.updatedUserProfile,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  backToMenu: () => dispatch(replace("/user")),
  updateUserProfile: (form, newAvatar) =>
    dispatch(updateUserProfileAction({ form, newAvatar })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer);
