import { replace } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoginEmailInputView from "../../../components/login/email/LoginEmailInputView";
import emailValidation from "../../../lib/emailValidation";
import { RootState } from "../../../modules";
import { firebaseEmailLoginRequestAction } from "../../../modules/firebaseAuthentication";
import { LoginRequest } from "../../../modules/loginRequests";
import { LoadingState } from "../../../modules/models";
import LoginErrorStateViewContainer from "../LoginErrorStateViewContainer";
import LoginLoadingStateViewContainer from "../LoginLoadingStateViewContainer";
import LoginEmailSentViewContainer from "./LoginEmailSentViewContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  currentRequest?: LoginRequest;
  emailLoginLoadingState: LoadingState;
  emailLoginSent: boolean;
}

interface DispatchProps {
  requestSignInMenu: (id: string) => void;
  firebaseEmailLoginRequest: (id: string, email: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  email: string;
}

class LoginEmailInputViewContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = { email: "" };
  }

  private handleEmailChange = (email: string) => this.setState({ email });

  private handleEnter = () => {
    const { currentRequest, firebaseEmailLoginRequest } = this.props;
    const id = currentRequest?.id;
    const { email } = this.state;
    if (!id || !emailValidation(email)) {
      return;
    }
    firebaseEmailLoginRequest(id, email);
  };

  private handleCancel = () => {
    const { currentRequest, requestSignInMenu } = this.props;
    const id = currentRequest?.id;
    if (!id) {
      return;
    }
    requestSignInMenu(id);
  };

  public render(): JSX.Element {
    const { currentRequest, emailLoginLoadingState, emailLoginSent } =
      this.props;
    const { email } = this.state;
    switch (emailLoginLoadingState) {
      case LoadingState.Loading:
        return <LoginLoadingStateViewContainer />;
      case LoadingState.Error:
        return <LoginErrorStateViewContainer />;
      case LoadingState.Initial:
      default:
        if (emailLoginSent) {
          return <LoginEmailSentViewContainer />;
        }
        return (
          <LoginEmailInputView
            serviceName={currentRequest?.clientName || ""}
            email={email}
            emailValidated={emailValidation(email)}
            onEmailChange={this.handleEmailChange}
            onEnter={this.handleEnter}
            onCancel={this.handleCancel}
          />
        );
    }
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentRequest: state.loginRequests.currentRequest,
  emailLoginLoadingState: state.firebaseAuthentication.emailLoginLoadingState,
  emailLoginSent: state.firebaseAuthentication.emailLoginSent,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  requestSignInMenu: (id: string) => dispatch(replace(`/login/${id}`)),
  firebaseEmailLoginRequest: (id: string, email: string) =>
    dispatch(firebaseEmailLoginRequestAction({ id, email })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginEmailInputViewContainer);
