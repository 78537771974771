import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    avatarContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    avatarImage: {
      height: "100%",
    },
    userName: {},
  });

export default styles;
