import { push, replace } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import UserConfigurationMenu from "../../components/user/UserConfigurationMenu";
import { RootState } from "../../modules";
import { UserProfile } from "../../modules/userProfile";
import { requestFirebaseSignOutAction } from "../../modules/firebaseAuthentication";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  userProfile?: UserProfile;
}

interface DispatchProps {
  navigateToEdit: () => void;
  navigateToGroups: () => void;
  backToIndex: () => void;
  requestFirebaseSignOut: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class UserConfigurationMenuContainer extends React.PureComponent<Props> {
  private handleSignOut = () => {
    const { backToIndex, requestFirebaseSignOut } = this.props;
    requestFirebaseSignOut();
    backToIndex();
  };

  public render(): JSX.Element {
    const { userProfile, navigateToEdit, navigateToGroups } = this.props;
    return (
      <UserConfigurationMenu
        hasApplication={false}
        userProfile={userProfile}
        onEdit={navigateToEdit}
        onGroups={navigateToGroups}
        onSignOut={this.handleSignOut}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  userProfile: state.userProfile.current,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigateToEdit: () => dispatch(push("/user/edit")),
  navigateToGroups: () => dispatch(push("/user/groups")),
  backToIndex: () => dispatch(replace("/")),
  requestFirebaseSignOut: () => dispatch(requestFirebaseSignOutAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserConfigurationMenuContainer);
