import { Button } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./UserConfigurationMenuStyles";
import MenuBox from "../parts/MenuBox";
import PageTitle from "../parts/PageTitle";
import { UserProfile } from "../../modules/userProfile";
import UserAvatarView from "../parts/UserAvatarView";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  userProfile: UserProfile | undefined;
  hasApplication: boolean;
  onEdit: () => void;
  onGroups: () => void;
  onSignOut: () => void;
}

const UserConfigurationMenu: React.FC<Props> = ({
  userProfile,
  hasApplication,
  onEdit,
  onGroups,
  onSignOut,
  classes,
  t,
}) => (
  <MenuBox>
    <PageTitle>{t("User Configurations Menu")}</PageTitle>
    <UserAvatarView userProfile={userProfile} />
    <div>
      <Button
        className={classes.menuButton}
        fullWidth
        variant="outlined"
        onClick={onEdit}
      >
        {t("Edit Profile")}
      </Button>
      <Button
        className={classes.menuButton}
        fullWidth
        variant="outlined"
        onClick={onGroups}
      >
        {t("Edit Groups")}
      </Button>
      {/**
       <Button className={classes.menuButton} fullWidth variant="outlined">
       {t("Edit Applications")}
       </Button>
       */}
      {hasApplication && (
        <Button
          className={classNames(classes.menuButton, classes.backButton)}
          fullWidth
        >
          {t("Back to Application")}
        </Button>
      )}
      {!hasApplication && (
        <Button
          className={classNames(classes.menuButton, classes.backButton)}
          fullWidth
          onClick={onSignOut}
        >
          {t("Sign Out")}
        </Button>
      )}
    </div>
  </MenuBox>
);

export default withTranslation()(withStyles(styles)(UserConfigurationMenu));
