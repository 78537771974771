import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { LoadableValue, LoadingState } from "../models";
import {
  createAsyncActionCreator,
  registerProgressAction,
} from "../actionCreatorHelpers";
import { MetaUser } from "../../../functions/src/models";

// Models
// ----------------------------------------

export type Group = {
  id: string;
  name: string;
  owners: string[];
  members: string[];
};

const EmptyGroup: Group = {
  id: "",
  name: "",
  owners: [],
  members: [],
};

export type GroupEditParameter = {
  name: string;
};

export const GroupInvitationStatus = {
  Invited: "invited",
  Accepted: "accepted",
  Expired: "expired",
  Cancelled: "cancelled",
  Declined: "declined",
};
export type GroupInvitationStatus =
  typeof GroupInvitationStatus[keyof typeof GroupInvitationStatus];
export const GroupInvitationListedTypes = [
  GroupInvitationStatus.Invited,
  GroupInvitationStatus.Expired,
  GroupInvitationStatus.Declined,
];

export type GroupInvitation = {
  id: string;
  email: string;
  status: GroupInvitationStatus;
  createdAt: number;
  expireAt: number;
};

export type GroupData = {
  group: Group;
  invitations: GroupInvitation[];
};

export type GroupFormData = {
  name: string;
};

export type GroupInviteFormData = {
  email: string;
};

export type GroupUserMetaData = {
  owners: MetaUser[];
  members: MetaUser[];
};

export const UserRole = {
  Owner: "owner",
  Member: "member",
};
export type UserRole = typeof UserRole[keyof typeof UserRole];

// State
// ----------------------------------------

export type GroupsState = {
  groups: LoadableValue<Group[]>;
  group: LoadableValue<GroupData | null>;
  groupOperationLoadingState: LoadingState;
  createdGroupId?: string;
};

const initialState: GroupsState = {
  groups: { loadingState: LoadingState.Initial },
  group: { loadingState: LoadingState.Initial },
  groupOperationLoadingState: LoadingState.Initial,
};

// Action Parameters
// ----------------------------------------

export interface WatchGroupsParameter {
  userId: string | undefined;
}

export interface ChangeGroupsParameter {
  groups: Group[];
}

export interface WatchGroupDetailParameter {
  userId?: string;
  groupId?: string;
}

export type ChangeGroupDetailParameter =
  | {
      data: "group";
      group: Group;
    }
  | {
      data: "invitations";
      invitations: GroupInvitation[];
    }
  | {
      data: "both";
      group: Group;
      invitations: GroupInvitation[];
    };

export interface CreateGroupParameter {
  values: GroupEditParameter;
}

export interface GetGroupParameter {
  id: string;
  userId: string;
}

export interface EditGroupParameter {
  id: string;
  values: GroupEditParameter;
}

export interface DeleteGroupParameter {
  id: string;
}

export interface InviteToGroupParameter {
  id: string;
  email: string;
}

export interface CancelInvitationToGroupParameter {
  groupId: string;
  invitationId: string;
}

export interface ChangeMemberRoleInGroupParameter {
  groupId: string;
  userId: string;
  role: UserRole;
}

export interface RemoveMemberFromGroupParameter {
  groupId: string;
  userId: string;
}

// Action Creators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeIDp/Groups");

export const watchGroupsAction =
  actionCreator<WatchGroupsParameter>("WatchGroups");
export const changeGroupsAction =
  actionCreator<ChangeGroupsParameter>("ChangeGroups");

export const watchGroupDetailAction =
  actionCreator<WatchGroupDetailParameter>("WatchGroup");
export const changeGroupDetailAction =
  actionCreator<ChangeGroupDetailParameter>("ChangeGroup");

export const [getGroupAction, getGroupProgressAction] =
  createAsyncActionCreator<GetGroupParameter, GroupData | null, Error>(
    actionCreator,
    "getGroup",
  );

export const [createGroupAction, createGroupProgressAction] =
  createAsyncActionCreator<CreateGroupParameter, Group | null, Error>(
    actionCreator,
    "createGroup",
  );

export const [editGroupAction, editGroupProgressAction] =
  createAsyncActionCreator<EditGroupParameter, GroupData | null, Error>(
    actionCreator,
    "editGroup",
  );

export const [deleteGroupAction, deleteGroupProgressAction] =
  createAsyncActionCreator<DeleteGroupParameter, GroupData | null, Error>(
    actionCreator,
    "deleteGroup",
  );

export const [inviteToGroupAction, inviteToGroupProgressAction] =
  createAsyncActionCreator<InviteToGroupParameter, GroupData | null, Error>(
    actionCreator,
    "inviteToGroup",
  );

export const [
  cancelInvitationToGroupAction,
  cancelInvitationToGroupProgressAction,
] = createAsyncActionCreator<
  CancelInvitationToGroupParameter,
  GroupData | null,
  Error
>(actionCreator, "cancelInvitationToGroup");

export const [
  changeMemberRoleInGroupAction,
  changeMemberRoleInGroupProgressAction,
] = createAsyncActionCreator<
  ChangeMemberRoleInGroupParameter,
  GroupData | null,
  Error
>(actionCreator, "changeMemberRoleInGroup");

export const [
  removeMemberFromGroupAction,
  removeMemberFromGroupProgressAction,
] = createAsyncActionCreator<
  RemoveMemberFromGroupParameter,
  GroupData | null,
  Error
>(actionCreator, "removeMemberFromGroup");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(changeGroupsAction, (state, { groups }) => ({
    ...state,
    groups: {
      loadingState: LoadingState.Initial,
      value: groups,
    },
  }))
  .case(watchGroupDetailAction, (state, { groupId }) =>
    groupId
      ? { ...state, group: { loadingState: LoadingState.Loading } }
      : state,
  )
  .case(changeGroupDetailAction, (state, data) => {
    const value = state.group.value || {
      group: { ...EmptyGroup },
      invitations: [],
    };
    switch (data.data) {
      case "group":
        value.group = data.group;
        break;
      case "invitations":
        value.invitations = data.invitations;
        break;
      case "both":
        value.group = data.group;
        value.invitations = data.invitations;
        break;
      default:
        break;
    }
    return { ...state, group: { loadingState: LoadingState.Initial, value } };
  });

registerProgressAction(reducer, getGroupProgressAction, (state, { value }) => ({
  ...state,
  group: value,
}));

registerProgressAction(
  reducer,
  createGroupProgressAction,
  (state, { value }) => ({
    ...state,
    groupOperationLoadingState: value.loadingState,
    createdGroupId: value.value?.id,
  }),
);

registerProgressAction(
  reducer,
  editGroupProgressAction,
  (state, { value }) => ({
    ...state,
    groupOperationLoadingState: value.loadingState,
  }),
);

registerProgressAction(
  reducer,
  deleteGroupProgressAction,
  (state, { value }) => ({
    ...state,
    groupOperationLoadingState: value.loadingState,
  }),
);

registerProgressAction(
  reducer,
  inviteToGroupProgressAction,
  (state, { value }) => ({
    ...state,
    groupOperationLoadingState: value.loadingState,
  }),
);

registerProgressAction(
  reducer,
  cancelInvitationToGroupProgressAction,
  (state, { value }) => ({
    ...state,
    groupOperationLoadingState: value.loadingState,
  }),
);

registerProgressAction(
  reducer,
  changeMemberRoleInGroupProgressAction,
  (state, { value }) => ({
    ...state,
    groupOperationLoadingState: value.loadingState,
  }),
);

registerProgressAction(
  reducer,
  removeMemberFromGroupProgressAction,
  (state, { value }) => ({
    ...state,
    groupOperationLoadingState: value.loadingState,
  }),
);

export default reducer;
