import * as React from "react";
import { connect } from "react-redux";
import RouterFrame from "../components/RouterFrame";

interface ExportProps {
  children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class RouterFrameContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { children } = this.props;
    return <RouterFrame>{children}</RouterFrame>;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouterFrameContainer);
