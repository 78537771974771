import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./RouterFrameStyles";

import { ReactComponent as Logo } from "../resources/logo.svg";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const RouterFrame: React.FC<Props> = ({ children, classes }) => (
  <div className={classes.root}>
    <div className={classes.logoArea}>
      <Logo className={classes.logo} />
      <div className={classes.logoText}>C-time! ID</div>
    </div>
    {children}
    <div className={classes.copyright}>(C)PRODUCTION C-time! Co., Ltd.</div>
  </div>
);

export default withTranslation()(withStyles(styles)(RouterFrame));
