import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import styles from "./InvitationLoadingStateViewStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const InvitationLoadingStateView: React.FC<Props> = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress />
  </div>
);

export default withTranslation()(
  withStyles(styles)(InvitationLoadingStateView),
);
