import { Button, TextField } from "@mui/material";
import classNames from "classnames";
import { FormikProps } from "formik";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserProfileFormData } from "../../../modules/userProfile";
import styles from "./UserEditStyles";
import MenuBox from "../../parts/MenuBox";
import PageTitle from "../../parts/PageTitle";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  formikProps: FormikProps<UserProfileFormData>;
  avatar: React.ReactNode;
  onBack: () => void;
}

const UserEdit: React.FC<Props> = ({
  formikProps,
  onBack,
  avatar,
  classes,
  t,
}) => {
  const { values, touched, errors, handleChange } = formikProps;
  return (
    <MenuBox>
      <PageTitle>{t("Edit Profile")}</PageTitle>
      {avatar}
      <TextField
        fullWidth
        label={t("Name")}
        name="name"
        value={values.name}
        onChange={handleChange}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
      />
      <Button
        className={classes.menuButton}
        color="primary"
        variant="contained"
        fullWidth
        onClick={formikProps.submitForm}
      >
        {t("Save")}
      </Button>
      <Button
        className={classNames(classes.menuButton, classes.backButton)}
        fullWidth
        onClick={onBack}
      >
        {t("Back to Menu")}
      </Button>
    </MenuBox>
  );
};

export default withTranslation()(withStyles(styles)(UserEdit));
