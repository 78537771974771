import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "typed-redux-saga";
import { Action } from "typescript-fsa";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import { getApiFunctionsInstanceAsync } from "../firebase";
import { RootState } from "../index";
import { navigateLocationAction } from "../windows";
import {
  completeLoginRequestAction,
  CompleteLoginRequestParameter,
  completeLoginRequestProgressAction,
  createIdpMenuLoginAction,
  createIdpMenuLoginProgressAction,
  getLoginRequestAction,
  GetLoginRequestParameter,
  getLoginRequestProgressAction,
  saveCurrentLoginRequestAction,
} from "./index";

const lastLoginRequestIdKey = "CtimeID.lastLoginRequestId";

const getLoginRequestProgressSaga = bindAsyncAction(
  getLoginRequestProgressAction,
)(function* getLoginRequestProgressSagaAction(payload) {
  const functions = yield* call(getApiFunctionsInstanceAsync);
  const request = yield* call(functions.getLoginRequestAsync, payload.id);
  return {
    request,
    lastLoginRequestId: window.localStorage.getItem(lastLoginRequestIdKey),
  };
});

function* getLoginRequestSaga(action: Action<GetLoginRequestParameter>) {
  yield* call(getLoginRequestProgressSaga, action.payload);
}

const completeLoginRequestProgressSaga = bindAsyncAction(
  completeLoginRequestProgressAction,
)(function* completeLoginRequestProgressSagaAction(payload) {
  const functions = yield* call(getApiFunctionsInstanceAsync);
  const result = yield* call(functions.completeLoginRequestAsync, payload.id);
  if (result.redirectUri) {
    yield* put(navigateLocationAction({ url: result.redirectUri }));
  }
  return result;
});

function* completeLoginRequestSaga(
  action: Action<CompleteLoginRequestParameter>,
) {
  yield* call(completeLoginRequestProgressSaga, action.payload);
}

function* saveCurrentLoginRequestSaga() {
  const state = (yield* select()) as RootState;
  if (state.loginRequests.currentRequest) {
    window.localStorage.setItem(
      lastLoginRequestIdKey,
      state.loginRequests.currentRequest.id,
    );
  }
}

const createIdpMenuLoginProgressSaga = bindAsyncAction(
  createIdpMenuLoginProgressAction,
)(function* createIdpMenuLoginProgressSagaAction() {
  const functions = yield* call(getApiFunctionsInstanceAsync);
  return yield* call(functions.createIdpMenuLoginAsync);
});

function* createIdpMenuLoginSaga() {
  yield* call(createIdpMenuLoginProgressSaga);
}

export default function* watchLoginRequest(): SagaIterator {
  yield* takeEvery(getLoginRequestAction.type, getLoginRequestSaga);
  yield* takeEvery(completeLoginRequestAction.type, completeLoginRequestSaga);
  yield* takeEvery(
    saveCurrentLoginRequestAction.type,
    saveCurrentLoginRequestSaga,
  );
  yield* takeEvery(createIdpMenuLoginAction.type, createIdpMenuLoginSaga);
}
