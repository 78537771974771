import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
} from "@mui/material";
import { useRef } from "react";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import ReactAvatarEditor from "react-avatar-editor";
import styles from "./AvatarEditorStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  image: File | null;
  scale: number;
  onChangeScale: (value: number) => void;
  onCancel: () => void;
  onComplete: (blob: Blob) => void;
}

const AvatarEditor: React.FC<Props> = ({
  open,
  image,
  scale,
  onChangeScale,
  onCancel,
  onComplete,
  t,
}) => {
  const editorRef = useRef<ReactAvatarEditor>(null);

  const handleComplete = () => {
    const editor = editorRef.current;
    if (!editor) {
      return;
    }
    editor.getImage().toBlob((blob) => blob !== null && onComplete(blob));
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{t("Edit an avatar")}</DialogTitle>
      <DialogContent>
        <ReactAvatarEditor
          ref={editorRef}
          image={image || ""}
          width={300}
          height={300}
          scale={scale}
          borderRadius={150}
        />
        <Slider
          min={1}
          max={3}
          step={0.01}
          value={scale}
          onChange={(ev, value) => onChangeScale(value as number)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("Cancel")}</Button>
        <Button onClick={handleComplete}>{t("OK")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(withStyles(styles)(AvatarEditor));
