import { Theme } from "@mui/material";
import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      textAlign: "center",
    },
    message: {
      display: "block",
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(2),
    },
  });

export default styles;
