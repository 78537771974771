import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import styles from "./GroupDetailOwnerContextMenuStyles";
import { MetaUser } from "../../../../../functions/src/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  user: MetaUser | null;
  currentUserId: string;
  onRevoke: (user: MetaUser) => void;
  onRemove: (user: MetaUser) => void;
  onClose: () => void;
}

const GroupDetailOwnerContextMenu: React.FC<Props> = ({
  open,
  user,
  currentUserId,
  onRevoke,
  onRemove,
  onClose,
  classes,
  t,
}) => {
  const isCurrentUser = user && user.id === currentUserId;
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{user?.name}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {isCurrentUser && (
          <ListItem button disabled>
            <ListItemText>{t("Cannot action yourself")}</ListItemText>
          </ListItem>
        )}
        {!isCurrentUser && (
          <>
            <ListItem
              className={classes.destructive}
              button
              onClick={() => user && onRevoke(user)}
            >
              <ListItemText>{t("Revoke Owner Permission")}</ListItemText>
            </ListItem>
            <ListItem
              className={classes.destructive}
              button
              onClick={() => user && onRemove(user)}
            >
              <ListItemText>{t("Remove from Group")}</ListItemText>
            </ListItem>
          </>
        )}
        <ListItem button onClick={onClose}>
          <ListItemText>{t("Cancel")}</ListItemText>
        </ListItem>
      </List>
    </Dialog>
  );
};

export default withTranslation()(
  withStyles(styles)(GroupDetailOwnerContextMenu),
);
