import { replace } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoginMenuView from "../../components/login/LoginMenuView";
import { RootState } from "../../modules";
import { requestFirebaseGoogleSignInAction } from "../../modules/firebaseAuthentication";
import { LoginRequest } from "../../modules/loginRequests";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  currentRequest?: LoginRequest;
  lastLoginRequestId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
  requestFirebaseGoogleSignIn: () => void;
  requestEmailSignIn: (id: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class LoginMenuViewContainer extends React.PureComponent<Props> {
  private handleGoogleLogin = () => {
    const { requestFirebaseGoogleSignIn } = this.props;
    requestFirebaseGoogleSignIn();
  };

  private handleEmailLogin = () => {
    const { currentRequest, requestEmailSignIn } = this.props;
    const id = currentRequest?.id;
    if (!id) {
      return;
    }
    requestEmailSignIn(id);
  };

  public render(): JSX.Element {
    const { currentRequest } = this.props;
    return (
      <LoginMenuView
        serviceName={currentRequest?.clientName || ""}
        onGoogleLoginClick={this.handleGoogleLogin}
        onEmailLoginClick={this.handleEmailLogin}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentRequest: state.loginRequests.currentRequest,
  lastLoginRequestId: state.loginRequests.lastLoginRequestId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  requestFirebaseGoogleSignIn: () =>
    dispatch(requestFirebaseGoogleSignInAction()),
  requestEmailSignIn: (id) => dispatch(replace(`/login/${id}/email`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginMenuViewContainer);
