import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {},
  });

export default styles;
