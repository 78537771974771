import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import classNames from "classnames";
import { FormikProps } from "formik";
import styles from "./GroupEditStyles";
import PageTitle from "../../parts/PageTitle";
import MenuBox from "../../parts/MenuBox";
import { GroupFormData } from "../../../modules/groups";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  formikProps: FormikProps<GroupFormData>;
  isEdit: boolean;
  onBack: () => void;
}

const GroupEdit: React.FC<Props> = ({
  formikProps,
  isEdit,
  onBack,
  classes,
  t,
}) => {
  const { values, touched, errors, handleChange } = formikProps;
  return (
    <MenuBox>
      <PageTitle>{isEdit ? t("Edit Group") : t("New Group")}</PageTitle>
      <div className={classes.formElements}>
        <TextField
          fullWidth
          label={t("Name")}
          name="name"
          value={values.name}
          onChange={handleChange}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </div>
      <Button
        className={classes.menuButton}
        color="primary"
        variant="contained"
        fullWidth
        onClick={formikProps.submitForm}
      >
        {t("Save")}
      </Button>
      <Button
        className={classNames(classes.menuButton, classes.backButton)}
        fullWidth
        onClick={onBack}
      >
        {isEdit ? t("Back to Group") : t("Back to Menu")}
      </Button>
    </MenuBox>
  );
};

export default withTranslation()(withStyles(styles)(GroupEdit));
