import { Typography } from "@mui/material";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import BlockIcon from "@mui/icons-material/Block";
import styles from "./LoginErrorStateViewStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  reason: string;
}

const LoginErrorStateView: React.FC<Props> = ({ reason, classes, t }) => (
  <div className={classes.root}>
    <div className={classes.container}>
      <BlockIcon />
      <Typography className={classes.message}>
        {t(`Error by ${reason}`)}
      </Typography>
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(LoginErrorStateView));
