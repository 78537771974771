import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import Dropzone from "react-dropzone";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./AvatarViewStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  imageUrl: string;
  onFile?: (file: File) => void;
}

const AvatarView: React.FC<Props> = ({ imageUrl, onFile, classes, t }) => (
  <div className={classes.root}>
    <Dropzone accept="image/*" onDrop={(files) => onFile && onFile(files[0])}>
      {({ getRootProps, getInputProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={classes.imageContainer} {...getRootProps()}>
          <img
            className={classes.avatarImage}
            src={imageUrl}
            alt={t("Avatar Image")}
          />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  </div>
);

export default withTranslation()(withStyles(styles)(AvatarView));
