import * as React from "react";
import { connect } from "react-redux";
import LoginEmailSentView from "../../../components/login/email/LoginEmailSentView";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class LoginEmailSentViewContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return <LoginEmailSentView />;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginEmailSentViewContainer);
