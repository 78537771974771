import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { LoadableValue, LoadingState } from "../models";
import { createAsyncActionCreator } from "../actionCreatorHelpers";

// models
// ----------------------------------------

export interface LoginRequest {
  id: string;
  status: "initial" | "verified" | "error";
  errorReason?: "invalidRequest" | "expired" | "unknown";
  clientName: string;
}

export interface LoginCompletionResult {
  id: string;
  status: "verified" | "error";
  redirectUri?: string;
}

export interface IdpMenuLoginResult {
  id: string;
}

// State
// ----------------------------------------

export interface LoginRequestsState {
  requestLoadingState: LoadingState;
  currentRequest?: LoginRequest;
  lastLoginRequestId?: string;
  idpMenuLoginRequest: LoadableValue<IdpMenuLoginResult>;
}

export const EmptyLoginRequestsState: LoginRequestsState = {
  requestLoadingState: LoadingState.Initial,
  idpMenuLoginRequest: {
    loadingState: LoadingState.Initial,
  },
};

const initialState: LoginRequestsState = EmptyLoginRequestsState;

// Parameters
// ----------------------------------------

export interface GetLoginRequestParameter {
  id: string;
}

export interface GetLoginRequestResponse {
  request: LoginRequest;
  lastLoginRequestId: string | undefined;
}

export interface CompleteLoginRequestParameter {
  id: string;
}

export interface CompleteLoginRequestResponse {
  request: LoginRequest;
  status: "verified" | "error";
  redirectUri?: string;
}

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeIDp/LoginRequest");

export const [createIdpMenuLoginAction, createIdpMenuLoginProgressAction] =
  createAsyncActionCreator<void, IdpMenuLoginResult>(
    actionCreator,
    "createIdpMenuLogin",
  );

export const clearIdpMenuLoginAction = actionCreator<void>("clearIdpMenuLogin");

export const [getLoginRequestAction, getLoginRequestProgressAction] =
  createAsyncActionCreator<
    GetLoginRequestParameter,
    GetLoginRequestResponse,
    Error
  >(actionCreator, "GetLoginRequest");
export const saveCurrentLoginRequestAction = actionCreator<void>(
  "saveCurrentLoginRequest",
);

export const [completeLoginRequestAction, completeLoginRequestProgressAction] =
  createAsyncActionCreator<
    CompleteLoginRequestParameter,
    CompleteLoginRequestResponse,
    Error
  >(actionCreator, "CompleteLoginRequest");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(createIdpMenuLoginProgressAction.started, (state) => ({
    ...state,
    idpMenuLoginRequest: { loadingState: LoadingState.Loading },
  }))
  .case(createIdpMenuLoginProgressAction.done, (state, { result }) => ({
    ...state,
    idpMenuLoginRequest: { loadingState: LoadingState.Loading, value: result },
  }))
  .case(createIdpMenuLoginProgressAction.failed, (state) => ({
    ...state,
    idpMenuLoginRequest: { loadingState: LoadingState.Error },
  }))
  .case(clearIdpMenuLoginAction, (state) => ({
    ...state,
    idpMenuLoginRequest: { loadingState: LoadingState.Loading },
  }))
  .case(getLoginRequestProgressAction.started, (state) => ({
    ...state,
    requestLoadingState: LoadingState.Loading,
  }))
  .case(getLoginRequestProgressAction.done, (state, { result }) => ({
    ...state,
    requestLoadingState: LoadingState.Initial,
    currentRequest: result.request,
    lastLoginRequestId: result.lastLoginRequestId,
  }))
  .case(getLoginRequestProgressAction.failed, (state) => ({
    ...state,
    requestLoadingState: LoadingState.Error,
  }))
  .case(completeLoginRequestProgressAction.started, (state) => ({
    ...state,
    requestLoadingState: LoadingState.Loading,
  }))
  .case(completeLoginRequestProgressAction.done, (state, { result }) => {
    if (result.status === "error") {
      return { ...state, requestLoadingState: LoadingState.Error };
    }
    return { ...state, requestLoadingState: LoadingState.Loading };
  })
  .case(completeLoginRequestProgressAction.failed, (state) => ({
    ...state,
    requestLoadingState: LoadingState.Error,
  }));

export default reducer;
