import { Theme } from "@mui/material";
import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      background: theme.palette.background.default,
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      minHeight: "200px",
    },
  });

export default styles;
