import { createSelector } from "reselect";
import { RootState } from "../index";
import { GroupUserMetaData } from "./index";
import { diff } from "../../lib/arrayOperations";
import { selectCurrentUser } from "../userProfile/selectors";

export const selectIsGroupOwner = createSelector(
  [
    (state: RootState) => state.groups.group,
    selectCurrentUser,
    (state: RootState, groupId: string) => groupId,
  ],
  (group, user, groupId) => {
    if (!group.value || group.value?.group.id !== groupId || !user) {
      return false;
    }
    return group.value.group.owners.find((v) => v === user.id) !== undefined;
  },
);

export const selectGroupUserMetaData = createSelector(
  [
    (state: RootState) => state.groups.group,
    (state: RootState) => state.userProfile.metaUsers,
    (state: RootState, groupId: string) => groupId,
  ],
  (group, metaUsers, groupId) => {
    const result: GroupUserMetaData = {
      owners: [],
      members: [],
    };
    if (!group.value || group.value?.group.id !== groupId) {
      return result;
    }
    group.value.group.owners.forEach((id) => {
      if (metaUsers[id]) {
        result.owners.push(metaUsers[id]);
      } else {
        result.owners.push({ id, name: "", avatarUrl: "" });
      }
    });
    diff(group.value.group.members, group.value.group.owners).forEach((id) => {
      if (metaUsers[id]) {
        result.members.push(metaUsers[id]);
      } else {
        result.members.push({ id, name: "", avatarUrl: "" });
      }
    });
    return result;
  },
);
