import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    componentContainer: {
      padding: theme.spacing(2),
    },
  });

export default styles;
