import { Button, Input, Typography } from "@mui/material";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./LoginEmailCompletionViewStyles";
import MenuBox from "../../parts/MenuBox";
import PageSectionTitle from "../../parts/PageSectionTitle";
import PageTitle from "../../parts/PageTitle";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  serviceName: string;
  email: string;
  emailValidated: boolean;
  onEmailChange: (email: string) => void;
  onEnter: () => void;
}

const LoginEmailCompletionView: React.FC<Props> = ({
  serviceName,
  email,
  emailValidated,
  onEmailChange,
  onEnter,
  classes,
  t,
}) => (
  <MenuBox>
    <PageTitle>{t("Sign In Title", { name: serviceName })}</PageTitle>
    <PageSectionTitle>{t("Sign In Email Verify Title")}</PageSectionTitle>
    <Typography className={classes.description}>
      {t("Sign In Email Verify Description")}
    </Typography>
    <Input
      fullWidth
      autoFocus
      value={email}
      placeholder={t("Email Address")}
      onChange={(e) => onEmailChange(e.target.value)}
      onKeyDown={(e) => {
        if (emailValidated && e.code === "Enter") {
          onEnter();
        }
      }}
    />
    <Button
      className={classes.button}
      fullWidth
      variant="contained"
      disabled={!emailValidated}
      onClick={onEnter}
    >
      {t("Sign In Email")}
    </Button>
  </MenuBox>
);

export default withTranslation()(withStyles(styles)(LoginEmailCompletionView));
