import * as React from "react";
import { connect } from "react-redux";
import NotFoundPage from "../../components/pages/NotFoundPage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class NotFoundPageContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return <NotFoundPage />;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFoundPageContainer);
