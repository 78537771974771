import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router";
import GroupEdit from "../../../components/user/groups/GroupEdit";
import {
  createGroupAction,
  editGroupAction,
  getGroupAction,
  GroupData,
  GroupEditParameter,
  GroupFormData,
} from "../../../modules/groups";
import { RootState } from "../../../modules";
import { LoadableValue, LoadingState } from "../../../modules/models";
import UserLoadableValueContainer from "../UserLoadableValueContainer";
import UserLoadingStateViewContainer from "../UserLoadingStateViewContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  isNew: boolean;
  userId?: string;
  groupId?: string;
  createdGroupId?: string;
  group: LoadableValue<GroupData | null>;
  operationLoadingState: LoadingState;
}

interface DispatchProps {
  backToList: () => void;
  backToGroup: (groupId: string) => void;
  getGroup: (groupId: string, userId: string) => void;
  createGroup: (values: GroupEditParameter) => void;
  editGroup: (id: string, values: GroupEditParameter) => void;
}

interface State {
  initialValues: GroupFormData;
}

type Props = ExportProps & StateProps & DispatchProps;

class GroupEditContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = {
      initialValues: {
        name: "",
      },
    };
  }

  public componentDidMount() {
    this.loadGroupIfNeeded(undefined);
    this.updateInitialValues();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { group, operationLoadingState } = this.props;
    this.loadGroupIfNeeded(prevProps);
    if (group !== prevProps.group) {
      this.updateInitialValues();
    }
    if (
      operationLoadingState !== prevProps.operationLoadingState &&
      operationLoadingState === LoadingState.Initial
    ) {
      this.submitCompletion();
    }
  }

  private updateInitialValues = () => {
    const { groupId, group } = this.props;
    if (groupId && group && group.value && group.value.group.id === groupId) {
      this.setState({
        initialValues: {
          name: group.value.group.name,
        },
      });
    }
  };

  private handleSubmit = (values: GroupFormData) => {
    const { groupId, createGroup, editGroup } = this.props;
    if (groupId) {
      editGroup(groupId, values);
    } else {
      createGroup(values);
    }
  };

  private handleBack = () => {
    const { groupId, backToList, backToGroup } = this.props;
    if (groupId) {
      backToGroup(groupId);
    } else {
      backToList();
    }
  };

  private loadGroupIfNeeded(prevProps?: Readonly<Props>) {
    const { group, groupId, userId, getGroup } = this.props;
    if (!groupId || !userId) {
      return;
    }
    if (groupId === prevProps?.groupId && userId === prevProps?.userId) {
      return;
    }
    if (
      group.loadingState !== LoadingState.Initial ||
      group.value !== undefined
    ) {
      return;
    }
    getGroup(groupId, userId);
  }

  private submitCompletion() {
    const { groupId, createdGroupId, backToGroup } = this.props;
    if (groupId) {
      backToGroup(groupId);
    } else if (createdGroupId) {
      backToGroup(createdGroupId);
    }
  }

  private form(isEdit: boolean): JSX.Element {
    const { initialValues } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
      >
        {(formikProps) => (
          <GroupEdit
            formikProps={formikProps}
            isEdit={isEdit}
            onBack={this.handleBack}
          />
        )}
      </Formik>
    );
  }

  public render(): JSX.Element {
    const { groupId, group, operationLoadingState } = this.props;
    const isEdit = Boolean(groupId);
    if (operationLoadingState === LoadingState.Loading) {
      return <UserLoadingStateViewContainer />;
    }
    if (isEdit) {
      return (
        <UserLoadableValueContainer value={group}>
          {this.form(isEdit)}
        </UserLoadableValueContainer>
      );
    }
    return this.form(isEdit);
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: ExportProps & RouteComponentProps<{ id: string }>,
): StateProps => ({
  isNew: false,
  userId: state.userProfile.current?.id,
  groupId: ownProps.match.params.id,
  createdGroupId: state.groups.createdGroupId,
  group: state.groups.group,
  operationLoadingState: state.groups.groupOperationLoadingState,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  backToList: () => dispatch(replace("/user/groups/")),
  backToGroup: (groupId) => dispatch(replace(`/user/groups/${groupId}`)),
  getGroup: (groupId, userId) =>
    dispatch(getGroupAction({ id: groupId, userId })),
  createGroup: (values) => dispatch(createGroupAction({ values })),
  editGroup: (id, values) => dispatch(editGroupAction({ id, values })),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupEditContainer);
