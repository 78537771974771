import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { LoadableValue, LoadingState } from "../models";
import { MetaUser } from "../../../functions/src/models";
import {
  createAsyncActionCreator,
  registerProgressAction,
} from "../actionCreatorHelpers";

// Models
// ----------------------------------------

export type UserProfile = {
  id: string;
  avatarUrl: string;
  name: string;
  email: string;
};

export type UserProfileFormData = {
  name: string;
};

export type NewAvatarData = {
  fileId: string;
  avatarUrl: string;
};

// State
// ----------------------------------------

export type UserProfileState = {
  current?: UserProfile;
  newAvatarData: LoadableValue<NewAvatarData>;
  updateLoadingState: LoadingState;
  updatedUserProfile: boolean;
  metaUsers: Record<string, MetaUser>;
};

const initialState: UserProfileState = {
  newAvatarData: { loadingState: LoadingState.Initial },
  updateLoadingState: LoadingState.Initial,
  updatedUserProfile: false,
  metaUsers: {},
};

// Action Parameters
// ----------------------------------------

export interface WatchUserProfileParameter {
  userId: string | undefined;
}

export interface ChangeUserProfileParameter {
  userProfile?: UserProfile;
}

export interface UploadNewAvatarParameter {
  data: Blob;
  userId: string;
}

export interface UpdateUserProfileParameter {
  form: UserProfileFormData;
  newAvatar: string;
}

export interface RequestUserMetaDataParameter {
  userIds: string[];
}

export interface UpdateUserMetaDataParameter {
  metaUsers: MetaUser[];
}

// Action Creators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeIDp/UserProfile");

export const watchUserProfileAction =
  actionCreator<WatchUserProfileParameter>("WatchProfile");
export const changeUserProfileAction =
  actionCreator<ChangeUserProfileParameter>("ChangeProfile");

export const [uploadNewAvatarAction, uploadNewAvatarProgressAction] =
  createAsyncActionCreator<UploadNewAvatarParameter, NewAvatarData, Error>(
    actionCreator,
    "UploadNewAvatar",
  );
export const clearNewAvatarAction = actionCreator<UploadNewAvatarParameter>(
  "ClearUploadNewAvatar",
);

export const [updateUserProfileAction, updateUserProfileProgressAction] =
  createAsyncActionCreator<UpdateUserProfileParameter, UserProfile, Error>(
    actionCreator,
    "UpdateUserProfile",
  );

export const requestUserMetaDataAction =
  actionCreator<RequestUserMetaDataParameter>("RequestUserMetaData");
export const updateUserMetaDataAction =
  actionCreator<UpdateUserMetaDataParameter>("UpdateUserMetaData");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(watchUserProfileAction, (state) => ({
    ...state,
    current: undefined,
  }))
  .case(changeUserProfileAction, (state, { userProfile }) => ({
    ...state,
    current: userProfile,
  }))
  .case(updateUserMetaDataAction, (state, { metaUsers }) => {
    if (metaUsers.length === 0) {
      return state;
    }
    return {
      ...state,
      metaUsers: metaUsers.reduce(
        (current, v) => {
          const next = current;
          next[v.id] = v;
          return next;
        },
        { ...state.metaUsers },
      ),
    };
  });
registerProgressAction(
  reducer,
  uploadNewAvatarProgressAction,
  (state, { value }) => ({ ...state, newAvatarData: value }),
);
registerProgressAction(
  reducer,
  updateUserProfileProgressAction,
  (state, { value }) => ({
    ...state,
    updateLoadingState: value.loadingState,
    updatedUserProfile: value.loadingState === LoadingState.Initial,
    newAvatarData:
      value.loadingState === LoadingState.Initial
        ? { loadingState: LoadingState.Initial }
        : state.newAvatarData,
  }),
);

export default reducer;
