import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import styles from "./GroupDetailInvitationContextMenuStyles";
import { GroupInvitation } from "../../../../modules/groups";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  invitation: GroupInvitation | null;
  onInvitationCancel: (invitation: GroupInvitation) => void;
  onClose: () => void;
}

const GroupDetailInvitationContextMenu: React.FC<Props> = ({
  open,
  invitation,
  onInvitationCancel,
  onClose,
  classes,
  t,
}) => (
  <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle>{invitation?.email}</DialogTitle>
    <List sx={{ pt: 0 }}>
      <ListItem
        className={classes.destructive}
        button
        onClick={() => invitation && onInvitationCancel(invitation)}
      >
        <ListItemText>{t("Cancel Invitation")}</ListItemText>
      </ListItem>
      <ListItem button onClick={onClose}>
        <ListItemText>{t("Cancel")}</ListItemText>
      </ListItem>
    </List>
  </Dialog>
);

export default withTranslation()(
  withStyles(styles)(GroupDetailInvitationContextMenu),
);
