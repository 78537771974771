import { Theme } from "@mui/material";
import { StyleRules } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    logoArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "end",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    logo: {
      width: "64px",
      height: "64px",
    },
    logoText: {
      fontFamily: "'Readex Pro', sans-serif",
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 400,
      marginLeft: theme.spacing(1),
    },
    copyright: {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.grey.A700,
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
  });

export default styles;
