import { Action } from "typescript-fsa";
import { call, put, takeEvery } from "typed-redux-saga";
import { SagaIterator } from "redux-saga";
import {
  navigateLocationAction,
  NavigateLocationParameters,
  popBackPathAction,
  poppedBackPathAction,
  storeBackPathAction,
  StoreBackPathParameters,
} from "./index";

function* navigateLocationSaga(
  action: Action<NavigateLocationParameters>,
): SagaIterator {
  const { payload } = action;
  yield* call(() => {
    window.location.href = payload.url;
  });
}

function* storeBackPathSaga(
  action: Action<StoreBackPathParameters>,
): SagaIterator {
  const { payload } = action;
  yield* call(() => {
    window.localStorage.setItem("backPath", payload.path);
  });
}

function* popBackPathSaga(): SagaIterator {
  const path = window.localStorage.getItem("backPath");
  window.localStorage.removeItem("backPath");
  yield* put(poppedBackPathAction({ path }));
}

export default function* watchWindows(): SagaIterator {
  yield* takeEvery(navigateLocationAction, navigateLocationSaga);
  yield* takeEvery(storeBackPathAction, storeBackPathSaga);
  yield* takeEvery(popBackPathAction, popBackPathSaga);
}
