import { eventChannel, SagaIterator } from "redux-saga";
import {
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "typed-redux-saga";
import { Action } from "typescript-fsa";
import {
  collection,
  doc,
  getFirestore,
  getDoc,
  onSnapshot,
  query,
  where,
  getDocs,
  Firestore,
} from "firebase/firestore";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import {
  cancelInvitationToGroupAction,
  CancelInvitationToGroupParameter,
  cancelInvitationToGroupProgressAction,
  changeGroupDetailAction,
  changeGroupsAction,
  changeMemberRoleInGroupAction,
  ChangeMemberRoleInGroupParameter,
  changeMemberRoleInGroupProgressAction,
  createGroupAction,
  CreateGroupParameter,
  createGroupProgressAction,
  deleteGroupAction,
  DeleteGroupParameter,
  deleteGroupProgressAction,
  editGroupAction,
  EditGroupParameter,
  editGroupProgressAction,
  getGroupAction,
  GetGroupParameter,
  getGroupProgressAction,
  Group,
  GroupInvitation,
  GroupInvitationListedTypes,
  GroupInvitationStatus,
  inviteToGroupAction,
  InviteToGroupParameter,
  inviteToGroupProgressAction,
  removeMemberFromGroupAction,
  removeMemberFromGroupProgressAction,
  watchGroupDetailAction,
  WatchGroupDetailParameter,
  watchGroupsAction,
  WatchGroupsParameter,
} from "./index";
import { getApiFunctionsInstanceAsync } from "../firebase";
import { requestUserMetaDataAction } from "../userProfile";

const modifyInvitation = (invitation: GroupInvitation): GroupInvitation => {
  if (invitation.status === GroupInvitationStatus.Invited) {
    if (invitation.expireAt < new Date().getTime()) {
      return { ...invitation, status: GroupInvitationStatus.Expired };
    }
  }
  return invitation;
};

const invitationsQuery = (db: Firestore, id: string) => {
  const collectionRef = collection(db, `groups/${id}/invitations`);
  return query(
    collectionRef,
    where("status", "in", GroupInvitationListedTypes),
  );
};

function* watchGroupsSaga(action: Action<WatchGroupsParameter>) {
  if (!action.payload.userId) {
    return;
  }
  const { userId } = action.payload;
  const subscribe = () =>
    eventChannel<Group[]>((emitter) => {
      const db = getFirestore();
      const collectionRef = collection(db, "groups");
      const querySnapshot = onSnapshot(
        query(collectionRef, where("members", "array-contains", userId)),
        (snapshot) => {
          const list = snapshot.docs.map((v) => {
            const value = v.data() as Group;
            return { ...value, id: v.id };
          });
          emitter(list);
        },
      );
      return () => querySnapshot;
    });

  const channel = yield* call(subscribe);
  try {
    while (true) {
      const groups = yield* take(channel);
      yield* put(changeGroupsAction({ groups }));
    }
  } finally {
    if (yield* cancelled()) {
      channel.close();
    }
  }
}

function* watchGroupSaga(groupId: string) {
  const subscribe = () =>
    eventChannel<Group>((emitter) => {
      const db = getFirestore();
      const docRef = doc(db, `groups/${groupId}`);
      const querySnapshot = onSnapshot(docRef, (snapshot) => {
        emitter({ ...(snapshot.data() as Group), id: snapshot.id });
      });
      return () => querySnapshot;
    });

  const channel = yield* call(subscribe);
  yield* fork(function* stopWatcherSaga() {
    yield* take(watchGroupDetailAction);
    channel.close();
  });
  try {
    while (true) {
      const group = yield* take(channel);
      yield* put(changeGroupDetailAction({ data: "group", group }));
      yield* put(
        requestUserMetaDataAction({
          userIds: [...group.owners, ...group.members],
        }),
      );
    }
  } finally {
    if (yield* cancelled()) {
      channel.close();
    }
  }
}

function* watchInvitationsSaga(groupId: string) {
  const subscribe = () =>
    eventChannel<GroupInvitation[]>((emitter) => {
      const db = getFirestore();
      const querySnapshot = onSnapshot(
        invitationsQuery(db, groupId),
        (snapshot) => {
          const list = snapshot.docs.map((v) => {
            const value = modifyInvitation(v.data() as GroupInvitation);
            return { ...value, id: v.id };
          });
          emitter(list);
        },
      );
      return () => querySnapshot;
    });

  const channel = yield* call(subscribe);
  yield* fork(function* stopWatcherSaga() {
    yield* take(watchGroupDetailAction);
    channel.close();
  });
  try {
    while (true) {
      const invitations = yield* take(channel);
      yield* put(changeGroupDetailAction({ data: "invitations", invitations }));
    }
  } finally {
    if (yield* cancelled()) {
      channel.close();
    }
  }
}

function* watchGroupDetailSaga(action: Action<WatchGroupDetailParameter>) {
  if (!action.payload.userId || !action.payload.groupId) {
    return;
  }
  const { userId, groupId } = action.payload;

  // 初期データのロード
  const db = getFirestore();
  const docRef = doc(db, `groups/${groupId}`);
  const data = yield* call(getDoc, docRef);
  const group = { ...(data.data() as Group), id: data.id };
  yield* put(
    requestUserMetaDataAction({ userIds: [...group.owners, ...group.members] }),
  );

  yield* put(changeGroupDetailAction({ data: "both", group, invitations: [] }));

  // オーナー権限の時だけ招待の一覧をwatch
  const isOwner = group.owners.findIndex((v) => v === userId) >= 0;
  if (isOwner) {
    yield* all([
      call(watchGroupSaga, groupId),
      call(watchInvitationsSaga, groupId),
    ]);
  } else {
    yield* call(watchGroupSaga, groupId);
  }
}

const getGroupProgressSaga = bindAsyncAction(getGroupProgressAction)(
  function* sagaAction(payload) {
    const { id, userId } = payload;
    const db = getFirestore();
    const docRef = doc(db, `groups/${id}`);
    const data = yield* call(getDoc, docRef);
    const group = { ...(data.data() as Group), id: data.id };

    // オーナー権限の時だけ招待の一覧をfetch
    const isOwner = group.owners.findIndex((v) => v === userId) >= 0;
    if (isOwner) {
      const snapshot = yield* call(getDocs, invitationsQuery(db, id));
      const invitations = snapshot.docs.map((v) => ({
        ...modifyInvitation(v.data() as GroupInvitation),
        id: v.id,
      }));
      return {
        group,
        invitations,
      };
    }
    return { group };
  },
);
function* getGroupSaga(action: Action<GetGroupParameter>) {
  yield* call(getGroupProgressSaga, action.payload);
}

const createGroupProgressSaga = bindAsyncAction(createGroupProgressAction)(
  function* sagaAction(payload) {
    const functions = yield* call(getApiFunctionsInstanceAsync);
    return yield* call(functions.createGroupAsync, payload.values);
  },
);
function* createGroupSaga(action: Action<CreateGroupParameter>) {
  yield* call(createGroupProgressSaga, action.payload);
}

const editGroupProgressSaga = bindAsyncAction(editGroupProgressAction)(
  function* sagaAction(payload) {
    const functions = yield* call(getApiFunctionsInstanceAsync);
    return yield* call(functions.editGroupAsync, payload.id, payload.values);
  },
);
function* editGroupSaga(action: Action<EditGroupParameter>) {
  yield* call(editGroupProgressSaga, action.payload);
}

const deleteGroupProgressSaga = bindAsyncAction(deleteGroupProgressAction)(
  function* sagaAction(payload) {
    const functions = yield* call(getApiFunctionsInstanceAsync);
    return yield* call(functions.deleteGroupAsync, payload.id);
  },
);
function* deleteGroupSaga(action: Action<DeleteGroupParameter>) {
  yield* call(deleteGroupProgressSaga, action.payload);
}

const inviteToGroupProgressSaga = bindAsyncAction(inviteToGroupProgressAction)(
  function* sagaAction(payload) {
    const functions = yield* call(getApiFunctionsInstanceAsync);
    return yield* call(functions.inviteGroupAsync, payload.id, payload.email);
  },
);
function* inviteToGroupSaga(action: Action<InviteToGroupParameter>) {
  yield* call(inviteToGroupProgressSaga, action.payload);
}

const cancelInvitationToGroupProgressSaga = bindAsyncAction(
  cancelInvitationToGroupProgressAction,
)(function* getGroupDetailProgressSagaAction(payload) {
  const functions = yield* call(getApiFunctionsInstanceAsync);
  return yield* call(
    functions.cancelInvitationToGroupAsync,
    payload.groupId,
    payload.invitationId,
  );
});
function* cancelInvitationToGroupSaga(
  action: Action<CancelInvitationToGroupParameter>,
) {
  yield* call(cancelInvitationToGroupProgressSaga, action.payload);
}

const changeMemberRoleInGroupProgressSaga = bindAsyncAction(
  changeMemberRoleInGroupProgressAction,
)(function* sagaAction(payload) {
  const functions = yield* call(getApiFunctionsInstanceAsync);
  return yield* call(
    functions.changeMemberRoleInGroupAsync,
    payload.groupId,
    payload.userId,
    payload.role,
  );
});
function* changeMemberRoleInGroupSaga(
  action: Action<ChangeMemberRoleInGroupParameter>,
) {
  yield* call(changeMemberRoleInGroupProgressSaga, action.payload);
}

const removeMemberFromGroupProgressSaga = bindAsyncAction(
  removeMemberFromGroupProgressAction,
)(function* sagaAction(payload) {
  const functions = yield* call(getApiFunctionsInstanceAsync);
  return yield* call(
    functions.removeMemberFromGroupAsync,
    payload.groupId,
    payload.userId,
  );
});
function* removeMemberFromGroupSaga(
  action: Action<ChangeMemberRoleInGroupParameter>,
) {
  yield* call(removeMemberFromGroupProgressSaga, action.payload);
}

export default function* watchGroups(): SagaIterator {
  yield* takeLatest(watchGroupsAction, watchGroupsSaga);
  yield* takeEvery(watchGroupDetailAction, watchGroupDetailSaga);
  yield* takeEvery(getGroupAction, getGroupSaga);
  yield* takeEvery(createGroupAction, createGroupSaga);
  yield* takeEvery(editGroupAction, editGroupSaga);
  yield* takeEvery(deleteGroupAction, deleteGroupSaga);
  yield* takeEvery(inviteToGroupAction, inviteToGroupSaga);
  yield* takeEvery(cancelInvitationToGroupAction, cancelInvitationToGroupSaga);
  yield* takeEvery(changeMemberRoleInGroupAction, changeMemberRoleInGroupSaga);
  yield* takeEvery(removeMemberFromGroupAction, removeMemberFromGroupSaga);
}
