import * as React from "react";
import { connect } from "react-redux";
import InvitationReplyResultView from "../../components/invitations/InvitationReplyResultView";
import { InvitationReplyType } from "../../modules/invitations";
import { LoadableValue } from "../../modules/models";
import { RootState } from "../../modules";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  invitationReply: LoadableValue<InvitationReplyType>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class InvitationReplyResultViewContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { invitationReply } = this.props;
    const reply = invitationReply.value ?? InvitationReplyType.Decline;
    return <InvitationReplyResultView reply={reply} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  invitationReply: state.invitations.invitationReply,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationReplyResultViewContainer);
