import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Group } from "../../../functions/src/models";
import {
  createAsyncActionCreator,
  registerProgressAction,
} from "../actionCreatorHelpers";
import { LoadableValue, LoadingState } from "../models";

// Models
// ----------------------------------------

export interface GetInvitationResultBase {
  verificationId: string;
  type: string;
}

export interface GetInvitationGroupResult extends GetInvitationResultBase {
  type: "group";
  invitationId: string;
  group: Group;
}

export interface GetInvitationNotFoundResult extends GetInvitationResultBase {
  type: "notFound";
}

export interface GetInvitationExpiredResult extends GetInvitationResultBase {
  type: "expired";
}

export type GetInvitationResult =
  | GetInvitationGroupResult
  | GetInvitationNotFoundResult
  | GetInvitationExpiredResult;

export const InvitationErrorReason = {
  Generic: "generic",
  NotFound: "notFound",
  Expired: "expired",
};
export type InvitationErrorReason =
  typeof InvitationErrorReason[keyof typeof InvitationErrorReason];

export const InvitationReplyType = {
  Accept: "accept",
  Decline: "decline",
};
export type InvitationReplyType =
  typeof InvitationReplyType[keyof typeof InvitationReplyType];

// State
// ----------------------------------------

export type InvitationsState = {
  invitation: LoadableValue<GetInvitationResult>;
  invitationReply: LoadableValue<InvitationReplyType>;
};

const initialState: InvitationsState = {
  invitation: { loadingState: LoadingState.Initial },
  invitationReply: { loadingState: LoadingState.Initial },
};

// Action Parameters
// ----------------------------------------

export type GetInvitationParameter = {
  verificationId: string;
};

export type ReplyInvitationParameter = {
  groupId: string;
  invitationId: string;
  verificationId: string;
  reply: InvitationReplyType;
};

// Action Creators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeIDp/Invitations");

export const [getInvitationAction, getInvitationProgressAction] =
  createAsyncActionCreator<GetInvitationParameter, GetInvitationResult, Error>(
    actionCreator,
    "getInvitation",
  );

export const [replyGroupInvitationAction, replyGroupInvitationProgressAction] =
  createAsyncActionCreator<ReplyInvitationParameter, void, Error>(
    actionCreator,
    "replyGroupInvitation",
  );

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState);

registerProgressAction(
  reducer,
  getInvitationProgressAction,
  (state, { value }) => ({
    ...state,
    invitation: value,
  }),
);

registerProgressAction(
  reducer,
  replyGroupInvitationProgressAction,
  (state, { value, params }) => ({
    ...state,
    invitationReply: {
      loadingState: value.loadingState,
      value:
        value.loadingState === LoadingState.Initial
          ? params?.reply ?? InvitationReplyType.Decline
          : undefined,
    },
  }),
);

export default reducer;
