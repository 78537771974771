import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import AvatarEditor from "../../../components/user/edit/AvatarEditor";
import AvatarView from "../../../components/user/edit/AvatarView";
import { RootState } from "../../../modules";
import { LoadableValue } from "../../../modules/models";
import {
  NewAvatarData,
  uploadNewAvatarAction,
  UserProfile,
} from "../../../modules/userProfile";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  userProfile?: UserProfile;
  newAvatarData?: LoadableValue<NewAvatarData>;
}

interface DispatchProps {
  uploadNewAvatar: (userId: string, data: Blob) => void;
}

interface State {
  pickedImage: File | null;
  scale: number;
}

type Props = ExportProps & StateProps & DispatchProps;

class AvatarViewContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = {
      pickedImage: null,
      scale: 1,
    };
  }

  private handleNewAvatar = (avatar: Blob) => {
    const { userProfile, uploadNewAvatar } = this.props;
    if (!userProfile) {
      return;
    }
    uploadNewAvatar(userProfile.id, avatar);
  };

  public render(): JSX.Element {
    const { userProfile, newAvatarData } = this.props;
    const { pickedImage, scale } = this.state;
    const imageUrl =
      newAvatarData?.value?.avatarUrl ?? userProfile?.avatarUrl ?? "";
    return (
      <>
        <AvatarView
          imageUrl={imageUrl}
          onFile={(file) => this.setState({ pickedImage: file })}
        />
        <AvatarEditor
          open={Boolean(pickedImage)}
          image={pickedImage}
          scale={scale}
          onChangeScale={(value) => this.setState({ scale: value })}
          onCancel={() => this.setState({ pickedImage: null, scale: 1 })}
          onComplete={(value) => {
            this.setState({
              // eslint-disable-next-line react/no-unused-state
              pickedImage: null,
              scale: 1,
            });
            this.handleNewAvatar(value);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  userProfile: state.userProfile.current,
  newAvatarData: state.userProfile.newAvatarData,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  uploadNewAvatar: (userId, data) =>
    dispatch(uploadNewAvatarAction({ userId, data })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvatarViewContainer);
