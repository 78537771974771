import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import classNames from "classnames";
import { FormikProps } from "formik";
import styles from "./GroupInviteStyles";
import PageTitle from "../../parts/PageTitle";
import MenuBox from "../../parts/MenuBox";
import PageSectionTitle from "../../parts/PageSectionTitle";
import { GroupData, GroupInviteFormData } from "../../../modules/groups";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  formikProps: FormikProps<GroupInviteFormData>;
  group: GroupData;
  onBack: () => void;
}

const GroupInvite: React.FC<Props> = ({
  formikProps,
  group,
  onBack,
  classes,
  t,
}) => {
  const { values, touched, errors, handleChange } = formikProps;
  return (
    <MenuBox>
      <PageTitle>{t("Group Invite Member")}</PageTitle>
      <PageSectionTitle>{group.group.name}</PageSectionTitle>
      <div className={classes.formElements}>
        <TextField
          fullWidth
          label={t("Invitee Email")}
          name="email"
          value={values.email}
          onChange={handleChange}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
      </div>
      <Button
        className={classes.menuButton}
        color="primary"
        variant="contained"
        fullWidth
        onClick={formikProps.submitForm}
      >
        {t("Send Invitation")}
      </Button>
      <Button
        className={classNames(classes.menuButton, classes.backButton)}
        fullWidth
        onClick={onBack}
      >
        {t("Back to Group")}
      </Button>
    </MenuBox>
  );
};

export default withTranslation()(withStyles(styles)(GroupInvite));
