import { Button, Icon } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { withTranslation, WithTranslation } from "react-i18next";
import { ReactComponent as GoogleIcon } from "../../resources/google.svg";
import styles from "./LoginMenuViewStyles";
import MenuBox from "../parts/MenuBox";
import PageTitle from "../parts/PageTitle";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  serviceName: string;
  onGoogleLoginClick: () => void;
  onEmailLoginClick: () => void;
}

const LoginMenuView: React.FC<Props> = ({
  serviceName,
  onGoogleLoginClick,
  onEmailLoginClick,
  classes,
  t,
}) => (
  <MenuBox>
    <PageTitle>{t("Sign In Title", { name: serviceName })}</PageTitle>
    <div className={classes.inputBox}>
      <Button
        className={classNames(classes.providerButton, classes.googleButton)}
        fullWidth
        variant="outlined"
        startIcon={
          <Icon className={classes.googleIcon}>
            <GoogleIcon />
          </Icon>
        }
        onClick={onGoogleLoginClick}
      >
        {t("Sign in with Google")}
      </Button>
      <Button
        className={classes.providerButton}
        fullWidth
        variant="contained"
        onClick={onEmailLoginClick}
      >
        {t("Sign In Email")}
      </Button>
    </div>
  </MenuBox>
);

export default withTranslation()(withStyles(styles)(LoginMenuView));
